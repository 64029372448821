.slick {
  &-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: .9rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      margin-left: 2rem;
      line-height: 0;

      &:first-child {
        margin-left: 0;
      }

      &.slick-active {
        button {
          background: $white;
        }
      }
    }

    button {
      position: relative;
      width: 1.1rem;
      height: 1.1rem;
      background: rgba($white, .3);
      border-radius: 50%;
      font-size: 0;
      transition: background .3s;
    }
  }
}
