.documents {
  padding-top: 10rem;
  padding-bottom: 15.4rem;
  background: #fff;

  @include mobile {
    padding-top: 1.5rem;
    padding-bottom: 8rem;
  }

  &__list {
    margin: -6rem;

    @include mobile {
      margin: -3rem;
    }
  }

  &__li {
    padding: 6rem;

    @include mobile {
      padding: 3rem;
    }
  }

  &__head-title {
    margin-bottom: 7rem;

    @include mobile {
      margin-bottom: 3rem;
    }
  }

  &__content-list {
    margin: -1rem;
  }

  &__content-li {
    padding: 1rem;
  }
}
