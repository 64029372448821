.main-slider {
  $self: &;
  color: $white;
  margin-bottom: 3rem;

  @include mobile {
    margin-bottom: 0;
  }

  @include fromDesktop {
    .map-arrow {
      opacity: 0;
    }

    &:hover {
      .map-arrow {
        opacity: 1;
      }
    }
  }

  &--another {
    padding-bottom: 1.4rem;
    margin-bottom: 0;
    margin-top: 1.5rem;

    @include mobile {
      padding-bottom: 0;
    }

    .slick-dots {
      bottom: 3.5rem;
    }
  }

  &__slider {
    @include smallDesktop {
      padding-bottom: 2rem;
    }

    @include mobile {
      padding-bottom: 5rem;
    }

    #{$self}--another & {
      @include smallDesktop {
        padding-bottom: 0;
      }

      @include mobile {
        padding-bottom: 6rem;
      }
    }
  }

  &__item {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;

    &:first-child {
      opacity: 1;
      position: static;
    }

    &.slick-slide {
      opacity: 1;
      position: static;
    }
  }

  &__item-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    min-height: 43.7rem;

    @include mobile {
      position: relative;
      display: block;
      min-height: 13.8rem;
    }

    #{$self}--another & {
      min-height: 34.7rem;

      @include mobile {
        min-height: 17.9rem;
      }
    }
  }

  &__img-box {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    line-height: 0;
  }

  &__img-mask {
    position: relative;
    left: 0;
    top: 0;
    width: 60rem;
    height: auto;
    z-index: 5;

    @include mobile {
      width: 19.2rem;
    }

    #{$self}--another & {
      width: 48rem;

      @include mobile {
        width: 24.8rem;
      }
    }
  }

  &__content {
    width: 60%;
    text-align: right;
    text-transform: uppercase;
    padding-top: 4rem;
    margin-left: auto;
    position: relative;
    z-index: 10;

    @include mobile {
      padding-top: .6rem;
      padding-left: 0;
      padding-right: .3rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    & > * {
      @include mobile {
        position: relative;
        z-index: 5;
      }
    }

    #{$self}--another & {
      padding-top: 3rem;

      @include mobile {
        padding-top: .6rem;
      }
    }
  }

  &__title {
    font-size: 3.8rem;
    font-weight: 300;
    line-height: 5.2rem;
    margin-bottom: .9rem;

    @include smallDesktop {
      font-size: 3rem;
      line-height: 2.6rem;
      margin-bottom: .4rem;
    }

    @include mobile {
      font-size: 1.6rem;
      line-height: 2.2rem;
      margin-bottom: .5rem;
      width: 13.3rem;
    }

    #{$self}--another & {
      font-size: 2.4rem;
      margin-bottom: 0;

      @include mobile {
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
    }
  }

  &__desc {
    @include raleway;
    font-size: 7rem;
    font-weight: 900;
    line-height: 7.5rem;
    margin-bottom: .4rem;

    @include smallDesktop {
      font-size: 5.6rem;
      line-height: normal;
      margin-bottom: .8rem;
    }

    @include mobile {
      font-size: 1.8rem;
      width: 17.1rem;
      margin-bottom: -.3rem;
    }

    #{$self}--another & {
      font-size: 5.5rem;
      line-height: 5.5rem;
      margin-bottom: 0;
      color: #A9AFB9;

      @include mobile {
        font-size: 1.8rem;
        line-height: 2.1rem;
      }
    }

    #{$self}--internet & {
      color: #EDCCA6;
    }
  }

  &__price {
    font-size: 3.8rem;
    line-height: 5.1rem;
    color: $orange;
    font-weight: 300;

    @include smallDesktop {
      font-size: 2.8rem;
      line-height: normal;
    }

    @include mobile {
      font-size: 1.4rem;
      margin: 0 0.5rem;
    }

    #{$self}--red & {
      color: #fff;
    }

    #{$self}--internet & {
      color: #fff;
    }
  }

  &__price-number {
    font-size: 10rem;
    color: $orange;
    font-weight: bold;
    line-height: 11.4rem;

    @include smallDesktop {
      font-size: 8rem;
      line-height: normal;
    }

    @include mobile {
      font-size: 4rem;
    }

    #{$self}--red & {
      color: $white;
    }

    #{$self}--internet & {
      color: #fff;
    }
  }

  .slick {
    &-dots {
      @include mobile {
        bottom: 1.5rem;
      }
    }
  }
}
