.news-catalog {
  margin-bottom: 17.8rem;

  @include mobile {
    margin-bottom: 6.8rem;
  }

  &__year-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 1.6rem;

    @include mobile {
      margin-bottom: 1.5rem;
    }
  }

  &__list-col {
    margin-bottom: 9rem;

    @include mobile {
      margin-bottom: 3.5rem;
    }
  }

  &__list-col-item {
    display: none;

    &.active {
      display: block;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: -.7rem;
  }

  &__li {
    width: calc(100% / 3);
    padding: .7rem;

    @include mobile {
      width: 100%;
    }

    &--66 {
      width: 66.6667%;

      @include mobile {
        width: 100%;
      }
    }
  }

  &__btn {
    width: 27rem;
    height: 5.2rem;
    font-size: 1.6rem;
    color: #3a5565;
    margin: 0 auto;
    border: 1px solid rgba(#3A5565, .3);
  }
}
