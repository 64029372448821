.users {
  background: #F0F5F9;
  padding: 10rem 0 17rem;
  margin-bottom: 10rem;

  @include mobile {
    padding: 4.7rem 0 4rem;
    margin-bottom: 4.8rem;
  }

  &__col-list {
    display: flex;
    flex-wrap: wrap;
    margin: -1.8rem;

    @include mobile {
      margin: -.75rem;
    }
  }

  &__col-li {
    width: calc(100% / 3);
    padding: 1.8rem;

    @include mobile {
      width: 100%;
      padding: .75rem;
    }
  }

  &__list {
    margin: -1.8rem;

    @include mobile {
      margin: -.75rem;
    }
  }

  &__li {
    padding: 1.8rem;

    @include mobile {
      padding: .75rem;
    }
  }
}
