.map-arrow {
  font-size: 5.5rem;
  transition: color .3s;
  line-height: 0;
  position: absolute;
  top: 50%;
  z-index: 50;
  color: rgba($white, .3);
  transition: opacity .3s, color .3s;

  @include mobile {
    display: none!important;
  }

  @include hover {
    color: rgba($white, .4);
  }

  &--left {
    left: 0;
    transform: translate(-50%, -50%);

    @include smallDesktop {
      left: 2rem;
      transform: translate(0, -50%);
    }
  }

  &--right {
    right: 0;
    transform: translate(50%, -50%);

    @include smallDesktop {
      right: 2rem;
      transform: translate(0, -50%);
    }
  }
}
