.channels {
  position: relative;

  &__container {
    padding: 8.1rem .5rem 14.7rem 1.5rem;

    @include mobile {
      padding: 3.3rem 1.5rem 5.7rem 1.5rem;
    }
  }

  &__tab-channels:not(.active) {
    display: none;
  }

  .head-title {
    margin-bottom: 3.2rem;
  }

  .rates__head {
    margin-bottom: 6.7rem;

    @include mobile {
      margin-bottom: 2.7rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 4.1rem;
    margin-right: -2rem;

    @include mobile {
      padding-bottom: 1.1rem;
      margin-right: auto;
    }
  }

  &__btn-more {
    display: block;
    margin: 0 auto;
    width: max-content;
    border: 1px solid $blueGrey;
    padding: 1.6rem 9.1rem 3.5rem 9.1rem;

    color: $blueGrey;
    font-size: 1.6rem;
    text-align: center;

    @include hover {
      color: $white;
      background-color: $blueGrey;
    }

    &:active {
      opacity: 0.6;
    }

    @include lessVerticalTablet {
      font-size: 1.5rem;
    }
  }
}
