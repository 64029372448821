.search-card {
  padding: 2rem 3rem;
  border: 1px solid #dadada;
  border-radius: .8rem;
  transition: border-color .3s;

  @include mobile {
    padding: 1.8rem 1.5rem;
  }

  @include hover {
    border-color: $orange;
  }

  &__header {
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: $orange;
    margin-bottom: .5rem;
    display: inline-block;

    @include mobile {
      font-size: 1.4rem;
      line-height: 1.9rem;
      margin-bottom: 1rem;
    }
  }

  &__desc {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #3A5565;

    @include mobile {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
}
