.search-result {
  margin: 10rem 0 16rem;

  @include mobile {
    margin: 3.4rem 0 6rem;
  }

  &__head-title {
    margin-bottom: 5.2rem;

    @include mobile {
      margin-bottom: 2.4rem;
    }
  }

  &__list {
    margin: -.5rem;
  }

  &__li {
    padding: .5rem;
  }

  &__content-col {
    margin-bottom: 5rem;

    @include mobile {
      margin-bottom: 3rem;
    }
  }

  &__btn {
    width: 27rem;
    padding: 0;
    height: 5.2rem;
    margin: 0 auto;

    @include mobile {
      width: 100%;
    }
  }
}
