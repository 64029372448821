.news-head {
  background: #F0F5F9;
  color: $black;
  margin-bottom: 5rem;

  @include mobile {
    margin-bottom: 2.7rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    @include mobile {
      display: block;
    }
  }

  &__content {
    flex: 1;
    padding-right: 9rem;
    align-self: center;

    @include smallDesktop {
      padding-right: 4rem;
    }

    @include mobile {
      padding-right: 0;
      padding-top: 2.8rem;
      margin-bottom: 1.7rem;
    }
  }

  &__date {
    margin-bottom: .3rem;
  }

  &__header {
    @include raleway;
    font-size: 4.8rem;
    line-height: 5.8rem;
    font-weight: 300;
    margin-bottom: 2rem;

    @include smallDesktop {
      font-size: 2.8rem;
      line-height: 3.8rem;
      margin-bottom: 1.2rem;
    }
  }

  &__desc {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #3a5565;

    @include smallDesktop {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }

    @include mobile {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }

  &__img-box {
    width: 57rem;
    height: 100%;

    @include smallDesktop {
      width: 45rem;
      height: auto;
    }

    @include mobile {
      width: 100%;
      height: auto;
      margin-left: -1.5rem;
      width: calc(100% + 3rem);
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;

    @include smallDesktop {
      height: auto;
    }
  }
}
