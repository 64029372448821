.news-detail {
  $self: &;
  margin-top: 6.2rem;
  margin-bottom: 10.4rem;

  @include mobile {
    margin-bottom: 4.5rem;
    margin-top: 2rem;
  }

  &__head {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 11rem;

    @include smallDesktop {
      margin-bottom: 5rem;
    }

    @include mobile {
      flex-direction: column;
      margin-bottom: 3rem;
    }
  }

  &__img-box {
    width: 57rem;
    height: 46rem;

    @include smallDesktop {
      width: 40rem;
      height: auto;
    }

    @include mobile {
      margin-left: -1.5rem;
      width: calc(100% + 3rem);
      order: 1;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
  }

  &__head-content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-left: 5.6rem;

    @include smallDesktop {
      padding-left: 2.6rem;
    }

    @include mobile {
      width: 100%;
      flex: none;
      padding-left: 0;
      margin-bottom: 3.4rem;
    }
  }

  &__date {
    margin-bottom: 1.1rem;
  }

  &__header {
    @include raleway;
    font-size: 4.8rem;
    line-height: 5.8rem;
    font-weight: 300;

    @include mobile {
      font-size: 2.8rem;
      line-height: 3.6rem;
    }
  }

  &__content {
    max-width: 72rem;
    margin: 0 auto;
  }

  &__get-back {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 11rem;

    @include smallDesktop {
      margin-top: 5rem;
    }
  }

  &__get-back-icon {
    width: 1.1rem;
    height: 2.2rem;
    color: #979797;
    transition: color .3s;
    margin-right: 3rem;

    @include mobile {
      margin-right: 1.5rem;
    }

    #{$self}__get-back:hover & {
      @include fromDesktop {
        color: $red;
      }
    }
  }

  &__get-back-text {
    font-size: 2rem;
    font-weight: 500;
    color: $black;

    @include mobile {
      font-size: 1.6rem;
    }
  }

  .user-text p {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #3a5565;
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
