.sales {
  background: #194594;
  padding: 10rem 0 12rem;

  @include mobile {
    padding: 4.9rem 0 4.5rem;
  }

  &__slider {
    padding-bottom: 9rem;

    @include mobile {
      padding-bottom: 3.8rem;
    }
  }

  &__img-col {
    height: 28rem;
    background: no-repeat center;
    background-size: cover;

    @include mobile {
      height: 14.3rem;
      max-width: 29rem;
      margin: 0 auto;
    }
  }

  &__img-col, &__img {
    border-radius: .8rem;
  }

  &__img {
    @include mobile {
      margin: 0 auto;
    }
  }

  .slick {
    &-track {
      display: flex;
      align-items: stretch;
    }

    &-slide {
      height: inherit;
      margin-left: 3rem;

      @include mobile {
        margin-left: 0;
      }
    }

    &-dots {
      bottom: 0;

      li {
        line-height: 0;

        &.slick-active {
          button {
            background: $white;

            &:before {
              background: $white;
            }
          }
        }
      }

      button {
        position: relative;
        width: 1.2rem;
        height: 1.2rem;
        padding: 0;
        margin: 0;
        background: rgba($white, .3);
        border-radius: 50%;
        font-size: 0;
        transition: background .3s;
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
          display: none;
        }
      }
    }
  }
}
