.sale-news {
  background: #15489D;

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__arrow {
    font-size: 18rem;
    color: #fff;

    @include mobile {
      display: none;
    }
  }

  &__content-col {
    padding-left: 7.2rem;
    flex: 1;
    padding-right: 2rem;

    @include smallDesktop {
      padding-left: 2rem;
    }

    @include mobile {
      padding-left: .8rem;
    }
  }

  &__date {
    margin-bottom: 1.2rem;

    @include mobile {
      margin-bottom: .7rem;
    }
  }

  &__header {
    font-size: 3.6rem;
    line-height: 4.6rem;
    font-weight: 300;
    color: $white;

    @include mobile {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  &__img-col {
    margin-left: auto;
    width: 25.6rem;
    height: 21.5rem;
    position: relative;

    @include mobile {
      width: 7.5rem;
      height: 7.5rem;
      margin-right: -1.5rem;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
  }
}
