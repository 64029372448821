.card-eq {
  $self: &;

  display: flex;
  align-items: center;
  padding: 3rem;
  border: .1rem solid rgba(#000, .5);
  border-radius: .8rem;

  @include mobile {
    flex-direction: column;
  }

  @include mobile {
    padding: 2rem 1.6rem 2.3rem;
  }

  &__name {
    min-width: 40rem;
    padding-right: 2.3rem;
    font-weight: 600;
    font-size: 2rem;

    @include desktop {
      font-size: 1.8rem;
      min-width: 36rem;
    }

    @include smallDesktop {
      flex: 1;
    }

    @include mobile {
      min-width: auto;
      text-align: center;
      font-size: 1.6rem;
      margin-bottom: 2rem;
      padding: 0;
    }
  }

  &__list {
    min-width: 22rem;
    display: flex;
    margin: 0 -2.3rem;

    @include desktop {
      margin: 0 -.5rem;
    }

    @include smallDesktop {
      margin-right: 0;
      min-width: 33rem;
    }

    @include mobile {
      margin: 0 -1.5rem;
      min-width: auto;
    }
  }

  &__item {
    padding: 0 2.3rem;

    @include desktop {
      padding: 0 .5rem;
    }

    @include mobile {
      padding: 0 1.5rem;
    }
  }

  &__wrapper {
    font-size: 1.4rem;
    color: rgba(#000, .5);
    font-weight: 600;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: .1rem;
      border-bottom: .1rem dashed rgba(#000, .5);
    }
  }

  &__cost {
    width: 13rem;
    margin-left: auto;
    font-size: 2rem;
    line-height: 2.7rem;

    @include desktop {
      font-size: 1.8rem;
    }

    @include mobile {
      margin: 1.8rem 0 1.6rem;
      text-align: center;
    }
  }

  &__button {
    width: 18.7rem;
    height: 5rem;
    margin-left: 6.5rem;

    @include desktop {
      width: 16rem;
      margin-left: 1.5rem;
      height: 4rem;
    }

    #{$self}.pick & {
      background: $orange;
      color: #fff;
    }
  }
}
