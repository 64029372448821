.card-advantage {
  border-radius: .6rem;
  color: $black;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &__img-box {
    border-radius: .6rem .6rem 0 0;
    background: #15489D;
    height: 13rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @include mobile {
      height: 9.9rem;
    }
  }

  &__icon {
    width: 8.5rem;
    height: 8.5rem;
    color: #E1654F;

    @include mobile {
      font-size: 5.5rem;
    }
  }

  &__content {
    padding: 3rem;
    border: 1px solid #E6E8EA;
    border-width: 0 1px 1px 1px;
    border-radius: 0 0 .6rem .6rem;
    flex: 1;

    @include mobile {
      padding: 2rem 2rem 2.8rem;
    }
  }

  &__header {
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;

    @include mobile {
      font-size: 1.4rem;
      line-height: 1.9rem;
      margin-bottom: 1rem;
    }
  }

  &__desc {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #3a5565;

    @include mobile {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
}
