.advantages {
  margin-bottom: 14rem;

  @include mobile {
    margin-bottom: 5rem;
  }

  &__head-title {
    margin-bottom: 4rem;

    @include mobile {
      margin-bottom: 3rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: -1rem;

    @include mobile {
      margin: 0;
      overflow: auto;
      margin-left: -1.5rem;
      width: calc(100% + 1.5rem);
      flex-wrap: nowrap;
    }
  }

  &__li {
    width: calc(100% / 3);
    padding: 1rem;

    @include mobile {
      padding: 0;
      margin-left: 1.5rem;
      min-width: 23.7rem;
      width: 23.7rem;
    }
  }

  &__card-advantage {
    white-space: normal;
  }
}
