.card-business {
  $self: &;
  background: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: .4rem;
  padding: 1.9rem 2.4rem 7.5rem;

  @include smallDesktop {
    padding-bottom: 5rem;
  }

  @include mobile {
    padding: 1.8rem 1.5rem 4.4rem;
  }

  &__icon-col {
    color: $black;
    line-height: 0;
    width: 7rem;
    height: 7rem;
    background: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      width: 5.4rem;
      height: 5.4rem;
    }
  }

  &__icon {
    width: 4rem;
    height: 4rem;

    @include mobile {
      width: 3rem;
      height: 3rem;
    }
  }

  &__title {
    font-size: 3.1rem;
    font-weight: 500;
    color: $white;

    @include smallDesktop {
      font-size: 2.4rem;
    }

    #{$self}--0 & {
      margin-top: 6.8rem;

      @include smallDesktop {
        margin-top: 4rem;
      }
    }

    #{$self}--1 & {
      margin-top: 8.8rem;

      @include smallDesktop {
        margin-top: 5rem;
      }
    }

    #{$self}--2 & {
      margin-top: 10.8rem;

      @include smallDesktop {
        margin-top: 6rem;
      }
    }

    @include mobile {
      margin-top: 6.4rem!important;
      font-size: 2.4rem;
    }
  }
}
