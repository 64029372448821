.gallery {
  &__container {
    padding: 10rem 0 19.1rem 0;

    @include mobile {
      padding: 2.1rem 2.3rem 3.1rem 1.5rem;
    }
  }

  &__albums-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2.9rem 3rem 1.9rem 3rem;
    position: relative;
    border: 1px solid #BFC6D0;
    background-color: #F8F9FB;
    border-radius: .8rem;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 9999;
      bottom: -1rem;
      right: -1rem;

      background-repeat: no-repeat;
      background-image: url("../svg_icons/rect.svg");
      background-size: auto;
      background-position: bottom right;
    }

    @include mobile {
      padding: 1.3rem 1.5rem 1.1rem 1.1rem;
    }
  }

  &__album {
    padding: 0 1.2rem 3.7rem 1.8rem;
    width: calc(100% / 3);

    @include lessVerticalTablet {
      width: 50%;
    }

    @include mobile {
      padding: 0 1rem 2.6rem 1rem;
    }

    @include smallMobile {
      width: 100%;
      max-width: 35rem;
      padding: 0 0 2.6rem 0;
    }
  }

  &__album-link {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__album-image {
    width: 32rem;
    height: auto;

    @include mobile {
      width: 25.2rem;
    }
  }

  &__image-wrapper {
    position: relative;
    margin: 0 auto;
  }

  &__info-block {
    text-align: left;
    width: 100%;
    margin: 0 auto;
  }

  &__photo-count {
    position: absolute;
    bottom: 1rem;
    right: 1rem;

    .date {
      line-height: 1.5rem;
    }
  }

  &__album-headline {
    padding: 1.6rem 0 0 0;

    @include raleway;
    font-size: 2.4rem;
    font-weight: normal;
    color: $black;

    @include mobile {
      padding: .8rem 0 0 0;

      font-size: 1.3rem;
      line-height: 2rem;
    }

    @include smallMobile {
      padding: .8rem 0 0 0;

      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  &__album-date {
    ;
    font-size: 1.4rem;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.50);
    line-height: 2.4rem;

    @include mobile {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }
}
