.not-found {
  background-color: #E1654F;

  &__container {
    padding: 22.1rem 0 20.3rem 0;
    max-width: none;
    text-align: center;

    @include mobile {
      padding: 6.5rem 1.4rem 6.8rem 1.6rem;
    }
  }

  &__image {
    width: 87.2rem;
    margin-left: -2.4rem;
    height: auto;

    @include desktop {
      margin: 0 auto;
    }

    @include mobile {
      width: 25.1rem;
    }
  }

  &__head-title {
    padding: 10rem 0 4.7rem 0;
    margin: 0;

    @include mobile {
      padding: 5.3rem 0 4.7rem 0;
    }
  }

  &__button {
    width: 16%;
    min-height: 5.4rem;
    margin: 0 auto;
    border: 1px solid white;

    @include hover {
      background-color: $white;

      .btn__text {
        color: $red;
      }
    }

    &:active {
      opacity: 0.6;
    }

    @include desktop {
      width: 30%;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__button.btn__text {
    color: $white !important;
  }
}
