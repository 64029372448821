.contacts-address {
  &__container {
    padding: 8.1rem 1.7rem 22rem 1.7rem;

    @include mobile {
      padding: 68px 1.7rem 8.3rem 1.7rem;
      color: red;
    }
  }

  .head-title {
    margin: 0;
    padding-bottom: 1.4rem;
    text-align: left;

    @include mobile {
      padding-bottom: 5.6rem;
    }
  }

  .user-text p {
    padding: 0 0 2.4rem 0;
    width: 60%;

    font-size: 1.4rem;
    color: $blueGrey;
    letter-spacing: 0;
    line-height: 2.4rem;

    @include mobile {
      padding: 0 0 2rem 0;
      width: 100%;
      font-size: 1.4rem;
      color: $blueText;
      line-height: 2rem;
    }
  }
}
