.channels-item {
  width: 50%;
  padding: 0 2rem 2rem 0;

  @include mobile {
    width: 100%;
    padding: 0 0 2rem 0;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem 3.8rem .7rem .9rem;
    border: .1rem solid #DADADA;
    transition: border-color .3s;
    border-radius: .8rem;
    pointer-events: auto;
    margin: 0;
    width: 100%;

    @include hover {
      border-color: $blue;
    }

    @include mobile {
      padding: 1rem 1.8rem .7rem .9rem;
    }
  }

  &__wrapper--tv .channels-item__img-wrapper {
    max-width: 10rem;
  }

  &__wrapper--partners .channels-item__img-wrapper {
    max-width: 30rem;
  }

  &__img-wrapper {
    height: 10rem;
    display: flex;
    align-items: center;
    flex: 2;

    @include mobile {
      max-width: 20rem;
      height: 5rem;
      max-height: 5rem;
    }
  }

  &__img {
    width: auto;
    height: auto;
  }

  &__name {
    display: block;
    padding: 0 1rem 0 3rem;
    margin-left: 0;
    text-align: right;
    flex: 1;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.7rem;
    color: $black;

      @include mobile {
        padding: 0 0 0 2rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
    }

    &--partners {
      text-align: right;
    }
  }

  &__count {
    padding: 1.1rem 1.3rem 1.1rem 1.3rem;
    margin-left: 2rem;
    background-color: #FFEBC6;
    border-radius: .4rem;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.7rem;
    color: $red;

    @include mobile {
      padding: .8rem .9rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
}
