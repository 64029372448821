.extra {
  padding-top: 10rem;
  padding-bottom: 12.7rem;

  @include mobile {
    padding-top: 3.7rem;
    padding-bottom: 4.7rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -1.5rem;

    @include mobile {
      margin: -.75rem;
    }
  }

  &__item {
    width: calc(100% / 3);
    padding: 1.5rem;

    @include mobile {
      width: 100%;
      padding: .75rem;
    }

    &--big {
      width: 50%;

      @include mobile {
        width: 100%;
      }
    }
  }
}
