.card-doc {
  padding: 3rem;
  border-radius: .8rem;
  border: 1px solid #DADADA;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include mobile {
    padding: 2rem 1.3rem 2rem 2rem;
    align-items: flex-start;
  }

  &__img-box {
    font-size: 5rem;
    line-height: 0;
  }

  &__title {
    flex: 1;
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 2rem;
    line-height: 2.7rem;
    font-weight: 500;
    color: $black;

    @include mobile {
      font-size: 1.6rem;
      line-height: 2.2rem;
      padding-left: 2.6rem;
      padding-right: 0;
    }
  }

  &__btn-col {
    margin-left: auto;

    @include mobile {
      width: 100%;
      padding-left: 7.6rem;
    }
  }

  &__btn {
    width: 24rem;
    height: 5rem;

    @include mobile {
      width: 16rem;
      height: 4rem;
      margin-top: 3rem;
    }
  }
}
