.history {
  $self: &;
  transition: opacity .4s;
  opacity: 0;
  margin-bottom: 14rem;

  @include mobile {
    margin-bottom: 5rem;
  }

  &.init {
    opacity: 1;
  }

  &__container {
    max-width: none;
    padding: 0;
  }

  &__head-title {
    margin-bottom: 3.2rem;

    @include mobile {
      width: 60%;
      margin: 0 auto 3rem auto;
    }
  }

  &__content {
    max-width: 90rem;
    margin: 0 auto;
    margin-bottom: 5rem;
    box-shadow: 0 6px 27px 0 #DADADA;
    border-radius: .8rem;
    position: relative;

    @include mobile {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      margin-bottom: 4rem;
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 50%;
      bottom: -1rem;
      transform: translateX(-50%);
      width: 2.2rem;
      height: 1.1rem;
      background: url(../img/css/triangle-bot.png) no-repeat center;
      background-size: 100% auto;
      z-index: 10;

      @include mobile {
        bottom: -1rem;
      }
    }
  }

  &__content-item {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 4.3rem 4.4rem 5.4rem;
    border-radius: .8rem;
    border: 1px solid #DADADA;

    @include mobile {
      padding: 2.5rem 2.5rem 1.1rem;
      flex-direction: column;
    }
  }

  &__desc {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #3a5565;
    flex: 1;
    padding-right: 4.5rem;

    @include mobile {
      flex: none;
      order: 1;
      padding-right: 0;
      font-size: 1.2rem;
      line-height: 1.8rem;
      margin-bottom: 1.6rem;
    }
  }

  &__img-box {
    width: 34.8rem;
    height: auto;

    @include mobile {
      width: 73%;
      margin-bottom: 2.6rem;
    }
  }

  &__img {
    width: 100%;
    height: auto;
  }

  &__bottom {
    position: relative;

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: calc(50% + .8rem);
      right: 0;
      transform: translateY(-50%);
      height: 1px;
      width: 100%;
      background: #15489D;
      pointer-events: none;
      z-index: 1;
    }
  }

  .slick-list {
    z-index: 10;
  }

  &__bottom-slide {
    padding-left: 5rem;

    @include mobile {
      padding-left: 3rem;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  &__bottom-item {
    cursor: pointer;
  }

  &__title {
    width: 13rem;
    height: 4rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #6d7d95;
    margin-bottom: 1.6rem;
    transition: color .3s;
    overflow: hidden;

    @include mobile {
      font-size: 1.2rem;
      line-height: 1.4rem;
      width: 10.4rem;
      margin-bottom: 2.8rem;
      height: 2.8rem;
    }

    #{$self}__bottom-slide.slick-current & {
      color: $orange;
    }

    #{$self}__bottom-item:hover & {
      @include fromDesktop {
        color: $orange;
      }
    }
  }

  &__circle-col {
    position: relative;

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: -5rem;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: #15489D;
      height: 1px;
    }
  }

  &__circle {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    background: #15489D;
    margin-bottom: 1.6rem;
    transition: background .3s;
    position: relative;
    z-index: 10;

    &:before, &:after {
      display: block;
      content: '';
      width: 4rem;
      height: 1px;
      background: $orange;
      position: absolute;
      top: 50%;
      z-index: 10;
      opacity: 0;
      transition: opacity .3s;

      #{$self}__bottom-slide.slick-current & {
        opacity: 1;
      }
    }

    &:before {
      right: 100%;
      transform: translate(50%, -50%);
    }

    &:after {
      left: 100%;
      transform: translate(-50%, -50%);
    }

    #{$self}__bottom-slide.slick-current & {
      background: $orange;
    }
  }

  &__year {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #6d7d95;

    #{$self}__bottom-slide.slick-current & {
      color: $orange;
    }

    #{$self}__bottom-item:hover & {
      @include fromDesktop {
        color: $orange;
      }
    }
  }
}
