.services {
  padding: 15.5rem 0 22.4rem 0;

  @include mobile {
    padding: 5.5rem 0 7.2rem 0;
  }

  &__container {
    @include lessFullHd {
      padding: 0 5rem;
    }

    @include lessHorizontalTablet {
      padding: 0 4rem;
    }

    @media (min-width: 70rem) and (max-width: 98rem) {
      padding: 0 8rem !important;
    }

    @include mobile {
      padding: 0 1.5rem;
    }
  }

&__card-icon {
  width: 10rem;
  height: 10rem;

  @include mobile {
    width: 8rem;
    height: 8rem;
  }
}

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;

    list-style-type: none;
  }

  &__list-item {
    width: 25%;
    transition: border-color 0.3s;

    &:last-of-type {
     @include smallMobile {
       width: 100%;
     }
    }

    @include mobile {
      width: 50%;
    }
  }

  &__list-item--mod {
    width: calc(100% / 4 * 3);

    @include mobile {
      width: 100%;
    }
  }

  &__list-item:not(&__list-item--mod) {
    border: .1rem solid $greyBorder;

    @include fromDesktop {
      border-right: none;

      &:nth-child(4) {
        border-right: .1rem solid $greyBorder;
      }

      &:nth-child(6) {
        border-top: none;
      }

      &:last-of-type {
        border-top: none;
        border-right: .1rem solid $greyBorder;
      }
    }

    @include hover {
      border-color: $blue;

      &:nth-child(6),
      &:last-of-type {
        margin-top: -.1rem;
        border-top: .1rem solid $blue;
      }

      &:nth-child(4),
      &:last-of-type {
        border-right-color: $blue;
      }
    }

    @include fromDesktop {
      &:hover + & {
        border-left: .1rem solid $blue;
      }
    }

    @media (min-width: 980px) {
      border-right: none;

      &:first-of-type {
        border-top-left-radius: .4rem;
        border-bottom-left-radius: .4rem;
      }

      &:nth-child(6),
      &:last-of-type {
        border-top: none;
      }

      &:nth-child(4),
      &:last-of-type {
        border-right: .1rem solid $greyBorder;
      }

      &:nth-child(4) {
        border-top-right-radius: .4rem;
      }

      &:nth-child(6) {
        border-bottom-left-radius: .4rem;
      }

      &:last-of-type {
        border-bottom-right-radius: .4rem;
      }
    }

    @include mobile {
      &:nth-child(odd) {
        border-right: none;
      }

      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: none;
      }

      &:nth-child(1)  {
        border-top-left-radius: .4rem;
      }

      &:nth-child(2) {
        border-top-right-radius: .4rem;
      }

      &:nth-child(6) {
        border-right: none;
      }

      &:last-of-type {
        border-right: .1rem solid $greyBorder;
      }
    }
  }

  &__card:not(&__card--mod) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 5.7rem 2.7rem 3.3rem 2.7rem;

    @include lessHorizontalTablet {
      padding: 5.7rem 2rem 3.3rem 2rem;
    }

    @include mobile {
      padding: 3.5rem 1.3rem 1.1rem 1.3rem;
    }
  }

  &__card--mod {
    padding: 5rem 5rem 0 0;
    height: 100%;

    @include fromDesktop {
      padding: 10.3rem 12rem 0 0;
    }

    @include mobile {
      padding: 5.5rem 0 5rem .3rem;
    }
  }

  &__card {
    height: 100%;
  }

  &__card--mod {
    .services__card-text {

      font-size: 2.4rem;
      color: $blueText;
      line-height: 4rem;

      @include mobile {
        font-size: 1.8rem;
        line-height: 3rem;
      }
    }

    .services__card-description {

      font-size: 1.6rem;
      font-weight: bold;
      color: $black;
      line-height: 2.6rem;

      @include mobile {
        font-size: 1.4rem;
        line-height: 2.4rem;
      }
    }
  }

  &__card-headline {
    padding: 3.4rem 0 2rem 0;

    @include raleway();
    font-size: 2.4rem;
    font-weight: normal;
    color: $black;

    @include mobile {
      padding: 6rem 0 1.7rem 0;

      font-size: 1.6rem;
    }
  }

  &__card-text {
    padding: 0 2rem 3.5rem 0;


    font-size: 1.4rem;
    color: $blueGrey;
    letter-spacing: 0;
    line-height: 2.4rem;

    @include mobile {
      padding: 0 .2rem 2.7rem 0;

      font-size: 1.2rem;
      line-height: 2rem;
    }
  }

  &__card-list {
    padding: .4rem 0 4rem 1.7rem;

    list-style-type: none;

    @include mobile {
      padding: 0 .2rem 4.8rem 1.7rem;
    }
  }

  &__card-list-item {
    position: relative;


    font-size: 1.4rem;
    color: $blueGrey;
    letter-spacing: 0;
    line-height: 2.4rem;

    @include mobile {
      font-size: 1.2rem;
      line-height: 2rem;
    }

    &::before {
      content: "—";
      position: absolute;
      left: -1.7rem;
      top: 0;
    }
  }

  .btn--red-pale {
    margin-top: auto;
    min-height: 5.1rem;

    @include mobile {
      padding: 0 1rem;
      min-height: auto;
    }
  }
}
