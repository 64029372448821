.oferta {
  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 2.7rem;

    @include mobile {
      margin-bottom: 1.5rem;
    }
  }

  &__popup-header {
    flex: 1;
    padding-right: 2rem;
    margin-bottom: 0!important;
  }

  &__close {
    width: 11.4rem;
    height: 5rem;
    padding: 0;

    @include mobile {
      width: auto;
      height: 3rem;
      font-size: 1.1rem;
      padding: 0 1.5rem;
    }

    &--bottom {
      margin-left: auto;
      margin-top: 8.5rem;
    }
  }

  &__list {
    margin: -3rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #6D7D95;
  }

  &__li {
    padding: 3rem;
  }

  &__text-list {
    margin: -1rem;
  }

  &__text-li {
    padding: 1rem;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 1.2rem;
  }

  p {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
