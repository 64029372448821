.card-user {
  background: $white;
  border-radius: .4rem;
  padding: 3rem 2.4rem 3.6rem;
  color: $black;

  @include mobile {
    padding: 3rem 1.4rem 3rem;
  }

  &__icon-col {
    margin-bottom: 1.2rem;

    @include mobile {
      line-height: 0;
    }
  }

  &__icon {
    color: $black;
    width: 3.8rem;
    height: 3.8rem;

    &.icon-docs {
      font-size: 4.8rem;
    }

    &.icon-cards {
      font-size: 5.3rem;
      stroke-width: inherit;
    }

    &.icon-checklist {
      font-size: 4.2rem;
      stroke-width: inherit;
    }
  }

  &__header {
    font-size: 1.8rem;
    font-weight: 500;
  }

  &__desc {
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: 2.2rem;

    @include mobile {
      margin-top: .7rem;
    }
  }

  &__list-col {
    margin-top: 2.6rem;

    @include mobile {
      margin-top: .5rem;
    }
  }

  &__list {
    margin: -.4rem;
  }

  &__li {
    padding: .4rem;
  }

  &__link {
    font-size: 1.4rem;
    color: rgba($black, .5);
    transition: color .3s;

    @include mobile {
      font-size: 1.2rem;
    }

    @include hover {
      color: $orange;
    }
  }

  &__form {
    margin-top: 2.8rem;

    @include mobile {
      margin-top: 2rem;
    }
  }

  &__form-list {
    display: flex;
    flex-wrap: wrap;
    margin: -.5rem;
  }

  &__form-li {
    padding: .5rem;

    &:nth-child(1) {
      flex: 1;
    }

    &:nth-child(2) {
      .label-field {
        &__input {
          width: 7.4rem;
        }
      }
    }
  }

  &__label-field {
    .label-field {
      &__input {
        border: 1px solid #1C61C8;
        border-radius: .4rem;
        width: 100%;
      }
    }
  }

  &__btn {
    margin-top: 3rem;

    @include mobile {
      height: 5rem;
      margin-top: 2.5rem;
    }
  }

  input[type=number] {
    -moz-appearance:textfield;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
