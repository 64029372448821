.about-company {
  margin-bottom: 16.6rem;

  @include mobile {
    margin-bottom: 5rem;
  }

  &__img-box {
    position: relative;
    margin-bottom: 16rem;

    @include mobile {
      margin-bottom: 2.7rem;
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 55.6878%;
      background: #E9EEF6;

      @include mobile {
        display: none;
      }
    }
  }

  &__img {
    display: block;
    margin: 0 auto;
    position: relative;
    z-index: 5;
  }

  &__head-title {
    margin-bottom: 3.6rem;

    @include mobile {
      margin-bottom: 3rem;
    }
  }

  &__content {
    border: 1px solid #D2D9DC;
    border-radius: .8rem;
    padding: 5rem 4.5rem 4.3rem 4.5rem;

    @include mobile {
      padding: 2.4rem 2rem;
    }
  }

  .user-text h3, p {
    color: #6D7D95;
    margin-bottom: 3.3rem;
  }

  &__text {
    font-size: 1.4rem;
    line-height: 2.6rem;
    color: #6d7d95;
    margin-bottom: 2rem;

    @include mobile {
      font-size: 1.2rem;
      line-height: 2rem;
      margin-bottom: 1.5rem;
    }

    &--bold {
      font-size: 1.6rem;
      font-weight: 500;

      @include mobile {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}
