// colors
$black: #000;
$white: #fff;
$blue: #15489D;
$blueText: #6D7D95;
$blueDark: #33424A;
$orange: #E1654F;
$red: #C92A2B;
$blueGrey: #3A5565;
$greenCircle: #6DD400;
$redCircle: #FD9560;
$greyCircle: #9EAFC8;
$orangeCircle: #F7B500;
$blueCircle: #32C5FF;
$purpleCircle: #AF60FF;
$greyBorder: #D8D8D8;
$lightBlueBackground: #F0F5F9;
$actionsBorder: #BFC6D0;
$indexBusinessBlack: #050910;
