.year-list {
  $self: &;

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__li {
    padding: .4rem;
  }

  &__item {
    height: 4rem;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: .3rem;
    font-size: 1.6rem;
    color: #3a5565;
    padding: 0 1.4rem;
    border: 1px solid #979797;
    transition: background .3s, color .3s, border-color .3s;

    #{$self}__li.active & {
      background: $orange;
      border-color: $orange;
      color: #FFEBC6;
    }

    @include hover {
      border-color: $orange;
    }
  }
}
