.contacts-map {
  height: 89.6rem;
  width: 100%;
  position: relative;

  @include mobile {
    height: 44.6rem;
  }

  &--search {
    @include mobile {
      padding: 17.6rem 0 0;
      height: 54.6rem;
    }
  }

  &__map {
    height: 100%;
    width: 100%;
  }

  &__block {
    width: 22.4rem;
    background-color: white;
    position: relative;
    box-shadow: 5px 16px 19px -4px rgba(0, 0, 0, 0.5);
  }

  &__block--status-offline .contacts-map__img--online {
    display: none;
  }

  &__block--status-online .contacts-map__img--offline {
    display: none;
  }

  &__block--status-online .contacts-map__text {
    color: #2AC510;
  }

  &__block--status-offline .contacts-map__text {
    color: #FF3500;
  }

  &__block--status-offline .contacts-map__address-button {
    display: none;
  }

  &__block .js-baloon-close {
    position: absolute;
    top: 0;
    right: .5rem;
    color: #5e6976;
  }

  &__text-block {
    display: flex;
    justify-content: space-between;
  }

  &__img {
    width: 5rem;
    height: 5rem;
  }

  &__content-block {

  }

  &__search-wrapper {
    position: absolute;
    top: 10rem;
    left: 10rem;
    width: 38.7rem;

    background: #FFFFFF;
    box-shadow: 0 .3rem .6rem rgba(0, 0, 0, 0.2);
    border-radius: .8rem;

    .label-field {
      border-top-left-radius: .8rem;
      border-top-right-radius: .8rem;
    }

    @include mobile {
      left: 0;
      top: 0;
      right: 0;
      width: auto;
    }
  }

  &__search-text-wrapper {
    padding: 0 1.3rem;
  }

  .user-text {
    padding: 1.9rem 0;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #4A4A4A;
    max-width: 33.7rem;
  }

  &__search-input {
    padding: 1.5rem;
    border-bottom: 1px solid #DBDBDB;
    border-radius: inherit;
    width: 100%;
    transition: color .3s;

    &::placeholder {
      font-weight: 300;
      font-size: 1.5rem;
      line-height: 1.8rem;
      color: #8F8F8F;
    }

    @include hover {
      border-color: $blue;
    }
  }

  &__search-button {
    position: relative;
    justify-content: space-between;
    padding: 1.5rem 0;
    text-align: left;
    border-top: 1px solid #DBDBDB;
    border-radius: 0;
    transition: border-color .3s;

    &:after {
      position: absolute;
      content: "";
      width: 3.1rem;
      height: 3.1rem;
      right: 0;
      display: block;

      background-image: url("../svg_icons/map-button-arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    .btn__text {
      margin-top: -.4rem;
      font-size: 1.5rem;
      line-height: 1.9rem;
      color: #68AEFF;
      transition: color .3s;
    }

    @include hover {
      background-color: inherit;
      border-color: $blue;

      .btn__text {
        color: $red;
      }
    }
  }

  &__text-block {
    justify-content: flex-start;
    border-bottom: 1px solid #D8D8D8;
  }

  &__text {
    padding: .8rem 1.2rem;
    max-width: 8.6rem;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  &__content-block {
    padding: 1rem;
  }

  &__address {
    display: block;
    padding-bottom: .9rem;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: #4A4A4A;
  }

  &__ip-address {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: #4A4A4A;
  }

  &__block-arrow {
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    bottom: -0.75rem;
    background: white;
    left: calc(100% / 2 - 0.75rem);
    transform: rotate(45deg);
    border-radius: .2rem;
  }
}
