.popup-header {
  font-size: 3.5rem;
  line-height: 4.8rem;
  color: $blue;
  margin-bottom: 2.7rem;

  @include mobile {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 1.5rem;
  }
}
