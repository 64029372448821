.card-package {
  $self: &;
  height: calc(100% - 13rem);
  border-radius: .6rem;

  @include mobile {
    height: 100%;
    display: flex;
    flex-direction: column;
    white-space: normal;
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 13rem;
    background: rgba(#FBFBFB, .85);
    border-radius: .6rem .6rem 0 0;

    @include mobile {
      height: 9.9rem;
    }

    img {
      @include mobile {
        height: 5.5rem;
      }
    }
  }

  &__content {
    border-radius: 0 0 .6rem .6rem;
    display: flex;
    flex-direction: column;
    min-height: 19rem;
    height: 100%;
    padding: 2.8rem;
    background: #fff;
    border: .1rem solid #E6E8EA;
    border-top: 0;

    @include mobile {
      padding: .9rem 1.4rem 2.7rem;
      min-height: auto;
      height: auto;
      flex: 1;
    }
  }

  &__title {
    margin: 0 0 2rem;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: 600;

    @include mobile {
      margin-bottom: .9rem;
    }
  }

  &__text {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #3A5565;

    @include mobile {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
}
