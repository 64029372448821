@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Medium.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Bold.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Light.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue.woff2') format('woff2'),
        url('../fonts/HelveticaNeue.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-Light.woff2') format('woff2'),
        url('../fonts/Raleway-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-ExtraBold.woff2') format('woff2'),
        url('../fonts/Raleway-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
  url('../fonts/Roboto-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
