.business {
  background: center right;
  background-repeat: no-repeat;
  background-color: #060709;
  padding: 11.1rem 0 20.2rem;

  @include mobile {
    padding: 2.5rem 0 4rem;
  }

  @include mobile {
    background-image: none!important;;
  }

  &__content {
    max-width: 51.2rem;
  }

  &__header {
    @include raleway;
    font-size: 4.8rem;
    line-height: 5.2rem;
    color: $white;
    font-weight: 300;
    margin-bottom: 1rem;
    text-transform: uppercase;

    @include mobile {
      font-size: 2.8rem;
      line-height: 3.6rem;
      margin-bottom: 2rem;
    }
  }

  &__header-title {
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 600;
    margin-bottom: 6rem;
    color: #6d7d95;

    @include mobile {
      font-size: 1rem;
      line-height: 1.4rem;
      margin-bottom: 1.5rem;
    }
  }

  &__desc {
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: bold;
    color: $white;
    margin-bottom: 3.8rem;

    @include mobile {
      font-size: 1rem;
      line-height: 1.4rem;
      margin-bottom: 1.5rem;
    }

    &--red {
      color: #E1654F;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
