.cookies {
  display: none;
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: 100%;
  background-color: $blue;
  width: calc(100% - 40rem);

  @include mobile {
    width: 100%;
  }

  &__active {
    display: block;
  }

  &__container {
    padding: .7rem 1.7rem;
    position: relative;
    max-width: calc(123rem + 34rem);
    padding-right: 17rem;

    @include mobile {
      padding: .7rem;
    }
  }

  &__text {
    max-width: 55rem;
    margin-bottom: 1.2rem;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $white;

    @include mobile {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  &__text-link {
    color: $red;

    @include hover {
      border-bottom: .1rem solid $red;
    }
  }

  &__btn {
    width: 14rem;
    font-size: 1.4rem;
    height: 3.2rem;

    @include mobile {
      font-size: 1.2rem;
      height: 2.8rem;
    }
  }

  &__btn-close {
    position: absolute;
    top: .7rem;
    right: 1.7rem;
    width: 3.7rem;
    height: 3.7rem;
    background: url("../svg_icons/btn-close.svg") no-repeat;
    background-size: 100% auto;

    @include hover {
      filter: brightness(2);
    }

    @include mobile {
      width: 2.7rem;
      height: 2.7rem;
      display: none;
    }
  }
}
