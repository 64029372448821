.card-extra {
  $self: &;

  padding: 3.5rem 2.9rem 3rem;
  background: #F0F5F9;
  background-repeat: no-repeat;
  border-radius: .8rem;

  @include mobile {
    padding: 3.1rem 6rem 3.6rem 2rem;
  }

  &--bg {
    padding-bottom: 8.8rem;
    padding-top: 3.1rem;

    @include mobile {
      padding-bottom: 7.8rem;
    }
  }

  .btn__icon--pick {
    display: none;
  }

  &.pick .btn__icon--pick {
    display: block;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 32.2rem;

    @include smallDesktop {
      width: 100%;
    }
  }

  &__name {
    margin: 0 0 .5rem;
    font-size: 1.8rem;
    font-weight: 600;

    #{$self}--bg & {
      color: #fff;
    }
  }

  &__amount {
    margin: 0 0 2.1rem;
    font-size: 1.4rem;
    color: #6D7D95;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: .1rem;
      border-bottom: .1rem dashed #6D7D95;
    }
  }

  &__text {
    margin: 0 0 3rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #3A5565;

    @include mobile {
      font-size: 1rem;
      line-height: 1.6rem;
    }

    #{$self}--bg & {
      color: #fff;
    }


  }

  &__btn {
    width: 16rem;
    height: 4rem;

    #{$self}.pick & {
      background: $orange;
      color: #fff;
    }
  }
}
