.gallery-category {
  &__container {
    padding: 10rem 0 8.8rem 0;

    @include mobile {
      padding: 2.1rem 2.3rem 3.1rem 1.5rem;
    }

    @include smallMobile {
      padding: 1.6rem 1.5rem 4.2rem 1.5rem;
    }
  }

  .head-title {
    margin-bottom: 7.0rem;

    @include smallMobile {
      display: none;
    }
  }

  &__photo-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__photo {
    padding: 0 1.2rem 3.3rem 1.8rem;
    width: calc(100% / 3);

    @include mobile {
      width: 50%;
    }

    @include smallMobile {
      width: 100%;
      padding: 0 0 1.5rem 0;
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    padding: 1.7rem 2.2rem 1.9rem 1.6rem;
    border: 1px solid #BFC6D0;
    background-color: #F8F9FB;
    border-radius: .8rem;

    @include smallMobile {
      padding: 1.4rem 1.4rem 1.1rem 1.4rem;
    }
  }

  &__photo-image {
    display: block;
    width: 34rem;
    height: auto;
    margin: 0 auto;

    @include mobile {
      width: 26rem;
      height: auto;
    }
  }

  &__photo-count {
    display: block;
    padding: 1.2rem 0 0 0;

    @include raleway;
    font-size: 1.8rem;
    color: #E1654F;

    @include smallMobile {
      font-size: 1.4rem;
    }
  }

  .news-detail__get-back {
    margin: 0;
    padding: 3.8rem 0 0 1.8rem;

    @include smallMobile {
      padding: 2.7rem 0 0 0;
    }
  }

  .news-detail__get-back-text {
    @include smallMobile {
      font-size: 2rem;
    }
  }

  .news-detail__get-back-icon {
    margin-right: 3.1rem;
  }

  &__img + .date {
    position: absolute;
    bottom: .9rem;
    right: 1rem;
    padding: 0 .3rem 0 .4rem;
    line-height: 1.5rem;
    margin: 0;
  }

  &__description {
    color: rgba(255, 255, 255, 0.50);

    @include mobile {
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.50);
      line-height: 1.8rem;
    }
  }

  &__content-col {
    @include  mobile {
      padding-left: 0 !important;
    }
  }
}
