*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none!important;
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 62.5%;
}

body {
  @include roboto;
  line-height: 1.5;

  &.overflow {
    overflow: hidden;
  }
}

p {
  margin: 0;
}

address {
  font-style: normal;
}

button {
  cursor: pointer;
  background: none;
  padding: 0;
  border: none;
  color: currentColor;
}

img {
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: bottom;
}

label {
  display: block;
  cursor: pointer;
  user-select: none;
}

textarea {
  resize: vertical;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

input[placeholder] {
  text-overflow: ellipsis;
  white-space: nowrap;
}

:focus::-webkit-input-placeholder {
  color: transparent;
}

:focus::-moz-placeholder {
  color: transparent;
}

:focus:-moz-placeholder {
  color: transparent;
}

:focus:-ms-input-placeholder {
  color: transparent;
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

button,
input,
optgroup,
select,
textarea {
  @include roboto;
  box-shadow: none;
  line-height: 2rem;
}

input {
  border: none;
}

input[type=text] {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a {
  text-decoration: none;
  color: currentColor;
}

.row {
  display: flex;
}

.site-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  min-height: 100vh;
  overflow: hidden;
}

.site-content {
  flex: 1 0 auto;
  position: relative;
  z-index: 1;

  &.hide {
    opacity: 0;
  }
}

.bg-cover {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup-open {
  overflow: hidden;
}

.ie11 {
  .select {
    &__btn {
      min-width: 18rem;
    }
  }
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
