.modal-form {
  &__block {
    display: flex;
    flex-wrap: wrap;
  }

  &__label {
    display: inline-block;
    padding: 0 2rem 2rem 0;
    width: 100%;

    &--street {
      flex: 3;
    }

    &--house {
      width: 8.2rem;
    }

    &--flat {
      width: 12.3rem;
    }

    &--phone {
      width: 100%;
    }

    &--textarea {
      width: 100%;
      min-height: 2.8rem;
    }
  }

  &__input {
    width: 100%;
    padding: 1rem;
    border-radius: 0;
  }

  &__input:not(&__input--textarea) {
    border-bottom: 1px solid #D8D8D8;
    transition: border-color .3s;

    @include hover {
      border-color: $blue;
    }
  }

  &__input--textarea {
    resize: vertical;
    border: 1px solid #D8D8D8;
    transition: border-color .3s;
    max-height: 30rem;
    min-height: 8rem;
    -webkit-appearance: none;

    @include hover {
      border-color: $blue;
    }
  }

  &__checkbox {
    padding: 0 0 4rem 0;
  }

  &__checkbox .checkbox__text {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $black;

    &::before {
      top: -0.1rem;
      border-color: rgba(109, 125, 149, 0.1972);
      background-color: rgba(109, 125, 149, 0.1);
    }

    &::after {
      filter: brightness(0.5);
      top: .3rem !important;
    }
  }

  &__buttons-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  &__submit-btn {
    width: 50%;
    max-width: 23rem;
    flex: 1;
    margin-right: 1rem;
    white-space: nowrap;
  }

  &__close-btn {
    width: 50%;
    display: none;
    flex: 0.4;

    @include mobile {
      display: flex;
    }
  }

  &__captcha {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 2rem;

    @include mobile {
      display: block;
    }

    label {
      width: auto;
      padding: 0;

      @include mobile {
        display: block;
      }
    }

    label:first-child {
      flex: 1;
      padding-right: 2rem;

      @include mobile {
        padding-right: 0;
        flex: none;
      }
    }

    label:nth-child(2) {
      margin-left: auto;

      @include mobile {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }
}
