.links {
  margin: 5rem 0;

  &__list {
    margin: -2rem;
    display: flex;
    flex-wrap: wrap;
  }

  &__li {
    position: relative;
    padding: 2rem;
    width: calc(100% / 3);
    align-items: stretch;

    @include mobile {
      width: 100%;
    }
  }

  &__item {
    height: 100%;
    border: 2px solid $black;
    padding: 2rem;
  }

  &__header {
    font-size: 3rem;
    color: $black;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  &__item-list {
    margin: -0.5rem;
  }

  &__item-li {
    position: relative;
    padding: 0.5rem;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 100%;
      transform: translateY(-50%);
      left: 0.5rem;
      right: 0.5rem;
      height: 1px;
      background: rgba($black, .5);
    }

    &:last-child:after {
      display: none;
    }
  }

  &__link {
    font-size: 1.8rem;
    line-height: normal;
    color: $black;
    transition: color .3s;
    text-transform: uppercase;

    @include hover {
      color: $red;
    }
  }
}
