//стили встроенных в Яндекс.Карты элементов

.popover {
  position: absolute;
  min-width: 18vw;
  min-height: 12vh;
  border: 1px solid black;
  background: white;
}

.popover--success {
  background: green;
}

.popover--error {
  background: red;
}

.close {
  text-decoration: none;
  float: right;
  padding: 5px 5px 0 0;
  font-size: 28px;
  line-height: 1;
}

.arrow {
  position: absolute;
  bottom: -15px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid black;
  left: calc(50% - 10px);
}
