.card-rate {
  $self: &;
  position: relative;
  border: 1px solid #dadada;
  border-radius: .8rem;
  padding: 3.5rem 4.5rem 0rem 4.5rem;
  color: $black;
  text-align: center;
  transition: transform .5s;

  @include mobile {
    padding: 1.9rem 2.5rem 2.2rem;
  }

  @include hover {
    transform: translateY(-1rem);
  }

  &--another {
    background: #fff;

    @include mobile {
      padding-bottom: 0;
    }
  }

  &__item {
    padding-left: 1.6rem;
  }

  &__sales {
    position: absolute;
    left: -1px;
    top: -2.2rem;
    right: -1px;
    height: 4.3rem;
    @include raleway;
    font-size: 1.5rem;
    font-weight: 900;
    color: $white;
    text-transform: uppercase;
    border-radius: .8rem .8rem 0 0;
    background: #15489D;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @include mobile {
      position: static;
      margin: -2rem -2.6rem 0;
    }
  }

  &__price {
    font-size: 3.6rem;
    font-weight: 500;
    transition: color .3s;

    #{$self}:hover & {
      @include fromDesktop {
        color: $orange;
      }
    }
  }

  &__month {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 1.6rem;
    text-transform: uppercase;
    transition: color .3s;

    #{$self}:hover & {
      @include fromDesktop {
        color: $orange;
      }
    }
  }

  &__service {
    font-size: 1.4rem;
    font-weight: 500;
    color: $blueText;
    margin-bottom: 3rem;

    @include mobile {
      margin-bottom: 2rem;
    }
  }

  &__list {
    margin: -1rem;
    text-align: left;
  }

  &__li {
    padding: 1rem;
  }

  &__key {
    font-size: 1rem;
    color: $blueText;
  }

  &__value {
    position: relative;
    font-size: 1.4rem;
    font-weight: 500;
    margin-left: -1.6rem;
    padding-left: 1.6rem;
  }

  &__value-icon-col {
    font-size: .8rem;
    color: $orange;
    line-height: 0;
    position: absolute;
    left: 0rem;
    top: 50%;
    transform: translateY(calc(-50% + .1rem));
  }

  &__value-icon {

  }

  &__value-text {
    font-size: 1.4rem;
    color: rgba($black, .5);
  }

  &__bottom {
    margin-top: 3.9rem;
    padding: 3.2rem 4.5rem 3rem 4.5rem;
    width: calc(100% + 9.2rem);
    margin-left: -4.6rem;
    margin-bottom: -.1rem;
    border-radius: 0 0 .8rem .8rem;
    transition: background .5s;

    #{$self}:hover & {
      @include fromDesktop {
        background: $orange;
      }
    }


    @include mobile {
      width: 100%;
      padding: 0;
      margin: 0;
      margin-top: 3rem;
    }

    #{$self}--another & {
      background: $orange;

      @include mobile {
        width: calc(100% + 5rem);
        margin-left: -2.5rem;
        background: $orange;
        padding: 2.8rem 0 2.3rem;
      }
    }
  }


  &__join {
    margin-bottom: 1.5rem;

    @include mobile {
      margin-bottom: .5rem;
    }

    #{$self}--another & {
      background: #EA8D72;
      color: #FFEBC6;

      @include mobile {
        width: 20rem;
        margin: 0 auto 1rem;
        background: #EA8D72;
        color: #fff;
      }
    }
  }

  &__link {
    font-size: 1.4rem;
    font-weight: 500;
    color: #15489d;
    transition: color .3s;

    #{$self}:hover & {
      @include fromDesktop {
        color: $white;
      }
    }

    #{$self}--another & {
      color: $white;
    }
  }
}
