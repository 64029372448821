.actions {
  &__container {
    padding: 9rem .6rem 11.9rem .6rem;

    @include lessVerticalTablet {
      padding: 5rem 1.5rem 5rem 1.5rem;
    }

    @media (min-width: 500px) and (max-width: 767px) {
      padding: 5rem 7rem 5rem 7rem;
    }
  }

  &__list-item--instruction .actions__image-wrapper {
    width: 100%;
    height: 26.1rem;

    @include mobile {
      height: 13rem;
    }
  }

  &__list-item--sales .actions__image-wrapper {
    width: 100%;
    height: 16.5rem;

    @include mobile {
      height: 13rem;
    }
  }

  &__list-item--half .actions__image-wrapper {
    width: 100%;
    height: 26.3rem;

    @include mobile {
      height: 13rem;
    }
  }

  &__image-wrapper {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include mobile {
      max-width: 26rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 5.2rem;
    list-style-type: none;
  }

  &__list-item {
    width: calc(100% / 3);
    padding: 1rem 1.4rem 2.1rem 1.4rem;

    &--half {
      width: 50%;

      .actions__ad {
        padding: 4.6rem 2.5rem 3.1rem 2.4rem;
      }

      .actions__description {
        padding: .9rem 3rem 1.7rem 0;
      }

      .actions__text {
        padding: 3.2rem 4rem 5.5rem 0;
      }
    }

    @include lessVerticalTablet {
      width: 100%;
      padding: 1rem 0;

      &--half {
        width: 100%;
        padding: 1rem 0;

        .actions__ad {
          padding: 1.4rem 1.4rem 1.5rem 1.4rem;
        }

        .actions__description {
          padding: .7rem 0 .1rem 0;
          line-height: 2.2rem;
        }

        .actions__text {
          padding: 1rem 0 1.7rem 0;
        }
      }
    }

    @media (min-width: 980px) {
      &:nth-child(1) .actions__ad {
        border-top-left-radius: 4px;
      }

      &:nth-child(2) .actions__ad {
        border-top-right-radius: 4px;
      }

      &:nth-child(3) .actions__ad {
        border-bottom-left-radius: 4px;
      }

      &:nth-child(5) .actions__ad {
        border-top-right-radius: 4px;
      }

      &:nth-child(6) .actions__ad {
        border-bottom-left-radius: 4px;
      }

      &:nth-child(8) .actions__ad {
        border-bottom-right-radius: 4px;
      }
    }

    @include mobile {
      .actions__ad {
        border-bottom-left-radius: 4px;
      }
    }
  }

  &__ad {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 2.9rem 2.9rem 3.1rem 2.4rem;
    height: 100%;
    transition: border-color .3s;

    border: 1px solid $actionsBorder;

    @include hover {
      border-color: $blue;
    }

    @include lessVerticalTablet {
      padding: 1.4rem 1.4rem 1.5rem 1.4rem;
    }

    @media (min-width: 500px) and (max-width: 767px) {
      padding: 3rem 5rem 3rem 5rem;
    }
  }

  &__link-item {
    padding: 1.7rem 0;
    color: $blue;

    @include hover {
      color: $red;
    }
  }

  &__link {
    font-size: 1.4rem;
    line-height: 2.4rem;

    @include  mobile {
      font-size: 1.2rem;
    }
  }

  &__link-item:not(:first-of-type) {
    border-top: 1px solid #BFC6D0;
    transition: color .3s;
  }

  @media (min-width: 500px) and (max-width: 767px) {
    &__list-item--half .actions__ad,
    .actions__ad {
      padding: 2rem 5rem;
    }

    &__image {
      width: 100%;
      height: auto;
    }

    &__description,
    &__text {
      padding-bottom: 2rem !important;
      padding-top: 2rem !important;
    }
  }

  .date {
    width: max-content;

    @include mobile {

      font-size: 10px;
      line-height: 12px;
    }
  }

  &__description {
    padding: .9rem 0 1.9rem 0;

    @include raleway();
    font-size: 2.4rem;
    font-weight: lighter;
    line-height: 3.1rem;
    color: $black;

    @include lessVerticalTablet {
      padding: .7rem 0 .1rem 0;
      line-height: 2.2rem;
      font-size: 1.6rem;
      margin-bottom: .6rem;
    }
  }

  &__text {
    padding: 3.1rem 0 5.5rem 0;


    font-size: 1.4rem;
    color: $blueGrey;
    letter-spacing: 0;
    line-height: 2.4rem;

    @include lessVerticalTablet {
      padding: 1rem 0 1.7rem 0;

      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }

  .btn {
    min-height: 4.8rem;

    @include mobile {
      min-height: auto;
      height: 4rem;
    }
  }

  .btn--red-pale {
    margin-top: auto;
    width: max-content;
    padding: 0 58px 0 58px;

    &:active {
      opacity: 0.6;
    }

    @include mobile {
      padding: 0 16px 0 16px;
      font-size: 1.5rem;
    }
  }

  .btn--white {
    display: block;
    margin: 0 auto;
    width: max-content;
    border: 1px solid $blueGrey;
    padding: 1.6rem 8.3rem 3.5rem 8.3rem;

    color: $blueGrey;
    font-size: 1.6rem;
    text-align: center;

    @include hover {
      color: $white;
      background-color: $blueGrey;
    }

    &:active {
      opacity: 0.6;
    }

    @include lessVerticalTablet {
      font-size: 1.5rem;
    }
  }
}
