.top-text {
  background-color: $lightBlueBackground;

  &__container {
    padding: 4.9rem 1.6rem 4.9rem 1.6rem;

    @include smallDesktop {
      padding: 3rem 1.5rem 3rem 1.5rem;
    }
  }

  &__headline {
    padding: 0 0 3rem 0;
    max-width: 85%;

    @include raleway();
    font-size: 4.8rem;
    font-weight: lighter;
    line-height: 8.1rem;
    text-transform: uppercase;
    color: $black;

    @include mobile {
      padding-bottom: 0;
      margin-bottom: 2rem;
      font-size: 2.8rem;
      line-height: 3.6rem;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 500;
    color: $black;
    margin-bottom: 1.5rem;

    @include mobile {
      margin-bottom: 1.1rem;
    }
  }

  .user-text p {
    padding: 0 0 1.3rem 0;
    max-width: 85%;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 2.4rem;
    color: $blueGrey;

    @include mobile {
      padding: 0 0 1.4rem 0;
    }

    &:last-child {
      padding: 0;
    }
  }

  .user-text h3 {
    margin-bottom: 2.9rem;

    @include mobile {
      margin-bottom: 2rem;
    }
  }
}
