.news {
  margin-bottom: 13rem;

  @include mobile {
    margin-bottom: 4.4rem;
  }

  &__head-title {
    @include mobile {
      margin-bottom: 3rem;
    }
  }

  &__slider {
    padding-bottom: 7.8rem;

    @include mobile {
      padding-bottom: 3.3rem;
    }
  }

  .slick {
    &-track {
      display: flex;
      align-items: stretch;
    }

    &-slide {
      height: inherit;
      margin-left: 2rem;
    }

    &-dots {
      bottom: 0;

      li {
        line-height: 0;

        &.slick-active {
          button {
            background: #15489D;
          }
        }
      }

      button {
        width: 1.1rem;
        height: 1.1rem;
        padding: 0;
        margin: 0;
        background: rgba(#15489D, .2);
        border-radius: 50%;
        font-size: 0;
        transition: background .3s;

        &:after {
          display: none;
        }
      }
    }
  }

  &__item {
    position: relative;
    border: 1px solid #dadada;
    border-radius: .6rem;

    @include mobile {
      width: 24.2rem;
    }
  }

  &__link {
    display: block;
  }

  &__img-col {
    margin-top: -.1rem;
    margin-left: -.1rem;
    width: calc(100% + .2rem);
    margin-bottom: 3.7rem;

    @include mobile {
      margin-bottom: 1.8rem;
    }
  }

  &__img {
    width: 100%;
    height: auto;
    border-top-right-radius: .6rem;
    border-top-left-radius: .6rem;
  }

  &__content {
    padding: 0 3.9rem 3.5rem;
    font-size: 1.8rem;
    line-height: 3.1rem;
    font-weight: 500;

    @include mobile {
      padding: 0rem .7rem 3.4rem 1.5rem;
    }
  }

  &__title {
    color: #15489D;
    margin-bottom: 1.2rem;
    transition: color .3s;

    @include hover {
      color: $red;
    }

    &:active {
      opacity: 0.6;
    }
  }

  &__title-text {
    border-bottom: 1px solid rgba($black, .3);
  }

  &__desc {
    color: #6D7D95;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
