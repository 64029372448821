.rates {
  $self: &;
  padding-top: 10.2rem;
  padding-bottom: 13rem;

  @include mobile {
    padding: 0;
    margin-bottom: 5rem;
  }

  &--blue {
    background: #F0F5F9;

    @include mobile {
      margin-bottom: 0;
      padding-bottom: 8.3rem;
      padding-top: 3.8rem;
    }
  }

  &__container {
    @include mobile {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__head-title {

    #{$self}--blue {
      @include mobile {
        margin-bottom: 4.3rem;
      }
    }
  }

  &__head {
    margin-bottom: 8.7rem;

    @include mobile {
      margin-bottom: 3.5rem;
      overflow: auto;
      overflow-y: hidden;
    }
  }

  &__head-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: -.4rem;

    @include mobile {
      display: block;
      white-space: nowrap;
      font-size: 0;
      margin: 0;
    }
  }

  &__head-li {
    padding: .4rem;

    @include mobile {
      padding: 0;
      margin-left: .8rem;
      display: inline-block;
    }
  }

  &__head-btn {
    font-size: 1.6rem;
    color: $blueText;
    height: 4.4rem;
    padding: 0 2.2rem;
    border: 1px solid transparent;
    border-radius: .4rem;
    transition: color .3s, border-color .3s;

    @include mobile {
      font-size: 1.2rem;
      padding: 0 .8rem;
      height: 3rem;
    }

    #{$self}__head-li.active & {
      color: #15489D;
      border-color: rgba(#15489D, .4);
    }

    @include hover {
      color: #15489d;
    }
  }

  &__content-li {
    display: none;

    &.active {
      display: block;
    }
  }

  &__card-list {
    display: flex;
    flex-wrap: wrap;
    margin: -1.6rem;

    @include mobile {
      display: block;
      margin: 0;
      overflow: auto;
      white-space: nowrap;
      font-size: 0;
    }
  }

  &__card-li {
    width: calc(100% / 4);
    padding: 1.6rem;

    @include smallDesktop {
      width: calc(100% / 3);
    }

    @include mobile {
      width: auto;
      display: inline-block;
      padding: 0;
      margin-left: 1.5rem;
    }
  }

  &__card-rate {
    width: 100%;

    @include mobile {
      width: 23.7rem;
    }
  }
}
