.page-footer {
  $self: &;

  @include mobile {
    background: #EBEFF5;
    padding: 4.7rem 0 4.9rem;
  }

  &__head {
    background: #F0F5F9;
    padding-top: 7.6rem;
    padding-bottom: 8.4rem;

    @include mobile {
      display: none;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -5.5rem;

    @include smallDesktop {
      margin: -1.2rem -5.5rem;
    }
  }

  &__li {
    padding: 5.5rem;

    @include smallDesktop {
      padding: 1.2rem 5.5rem;
    }
  }

  &__header {
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
    color: $black;
    margin-bottom: 1.8rem;
    transition: color .3s;
    display: block;

    @include hover {
      color: $orange;
    }
  }

  &__link-list {
    display: flex;
    flex-wrap: wrap;
    margin: -2.9rem;
  }

  &__link-li {
    padding: 2.9rem;
  }

  &__card-list {
    margin: -.5rem;
  }

  &__card-li {
    padding: .5rem;
  }

  &__link-header {
    font-size: 1.5rem;
    color: rgba(109, 125, 149, 0.6);
    margin-bottom: 1.1rem;
  }

  &__link {
    font-size: 1.4rem;
    font-weight: 500;
    color: $black;
    transition: color .3s;

    @include hover {
      color: $orange;
    }
  }

  &__link-quantity {
    color: #A6ACB4;
    transition: color .3s;

    #{$self}__link:hover & {
      @include fromDesktop {
        color: $orange;
      }
    }
  }

  &__bot {
    background: #EBEFF5;
    padding-top: 4.4rem;
    padding-bottom: 5.8rem;

    @include mobile {
      padding: 0;
    }
  }

  &__bot-container {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  &__info-col {
    width: 41.1rem;

    @include smallDesktop {
      width: 31.1rem;
    }
  }

  &__copyright {
    font-size: 1.6rem;
    font-weight: 500;
    color: $blueText;
    margin-bottom: 1.1rem;
    text-transform: uppercase;
  }

  &__location {
    font-size: 1.2rem;
    font-weight: 500;
    color: $blueText;
    margin-bottom: .8rem;

    @include mobile {
      font-size: 1rem;
      margin-bottom: .4rem;
    }
  }

  &__worktime {
    font-size: 1.2rem;
    font-weight: 500;
    color: rgba($blueText, .5);
    margin-bottom: .5rem;

    @include mobile {
      font-size: 1rem;
      margin-bottom: .2rem;
    }
  }

  &__attention {
    font-size: 1.2rem;
    font-weight: 500;
    color: rgba($blueText, .5);
    margin-top: 6.5rem;

    @include mobile {
      font-size: 1rem;
      line-height: 1.4rem;
      margin-top: 3rem;
    }
  }

  &__social-col {
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    margin-left: 13.8rem;
    padding-top: 6.6rem;

    @include smallDesktop {
      margin-left: auto;
    }

    @include mobile {
      margin-left: 0;
      display: none;
    }

    &--mobile {
      display: none;

      @include mobile {
        display: flex;
        padding-top: 0;
      }
    }
  }

  &__social-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: -.6rem;
  }

  &__social-li {
    padding: .6rem;
  }

  &__social-link {
    display: block;
    color: $blueText;
    font-size: 3.2rem;
    line-height: 0;
    transition: color .3s;

    @include hover {
      color: $orange;
    }
  }

  &__social-icon {

  }

  &__download-col {
    width: 31rem;
    margin-left: auto;
    padding-top: 1.4rem;

    @include mobile {
      width: 100%;
      margin-left: 0;
      margin-top: 2.7rem;
    }
  }

  &__download-header {
    font-size: 1.5rem;
    font-weight: 500;
    color: $blueText;
    margin-bottom: 1.9rem;

    @include mobile {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }

  &__app-list {
    display: flex;
    flex-wrap: wrap;
    margin: -.6rem;
  }

  &__app-li {
    padding: .6rem;

    @include mobile {
      width: calc(100% / 2);
    }
  }

  &__app-link {
    display: block;
  }

  &__app-img {
    width: 100%;
    height: auto;
    max-width: 25rem;
  }

  &__by-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 4.4rem;
    padding-right: 3rem;

    @include mobile {
      margin-top: 2.5rem;
      padding-right: 1.5rem;
    }
  }

  &__nkh {
    display: block;
    text-align: right;
    font-size: 7.8rem;
    color: $blueText;
    margin-left: auto;
    line-height: 0;
    transition: color .3s;

    @include hover {
      color: $orange;
    }

    @include mobile {
      margin-top: 0;
      display: inline-block;
      line-height: 0;
      margin-left: 0;
    }
  }
}
