.page-header {
  $self: &;
  position: relative;
  z-index: 999;
  height: 4rem;
  color: $black;

  @include mobile {
    height: 3rem;
  }

  &--black {
    background: $indexBusinessBlack;

    .page-header__tabs-link {
      color: #ffffff;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
  }

  &__header {
    #{$self}--another & {
      display: none;

      @include mobile {
        display: flex;
      }
    }
  }

  &__tabs {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    height: 100%;
  }

  &__tabs-li {
    height: 100%;
  }

  &__tabs-link {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0 3.2rem;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: rgba($black, .6);
    transition: color .3s, background .3s;

    @include mobile {
      font-size: 1rem;
      padding: 0 1rem;
    }

    #{$self}__tabs-li.active & {
      background: $blue;
      color: $white;

      #{$self}--red & {
        background: #C92A2B;
      }
    }
  }

  &__city {
    margin-left: 6.6rem;

    @include desktop {
      margin-left: 1rem;
    }

    @include mobile {
      display: none;
      height: 1.38rem;
    }

    &--menu {
      @include mobile {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: flex-start;
      }
    }

    .select {
      display: inline-block;

      &__placeholder-text {
        line-height: 1.4rem;
      }
    }
  }

  &__city-title {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 500;
    color: $blueText;
    margin-right: .3rem;
    display: inline-block;

    @include mobile {
      height: 100%;
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -1.1rem;
    margin-left: auto;

    @include smallDesktop {
      margin: -.3rem;
      margin-left: auto;
    }

    @include mobile {
      display: none;
    }
  }

  &__links-li {
    padding: 1.1rem;

    @include smallDesktop {
      padding: .3rem;
    }
  }

  &__link {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #33424a;
    transition: color .3s;

    @include hover {
      color: $orange;
    }
  }

  &__menu {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #F0F5F9;
    z-index: 999;
    overflow: auto;
    padding: .8rem 1.5rem 0;
    display: none;

    &.active {
      display: block;
    }

    &:after {
      display: block;
      content: '';
      height: 5.4rem;
      width: 100%;
    }

    .select {
      &__placeholder-text {
        font-size: 1rem;
      }

      &__icon-wrap {
        transform: translateY(calc(-50% + .1rem));

        @include mobile {
          transform: translateY(calc(-50% + .2rem));
        }
      }
    }
  }

  &__menu-open {
    color: $orange;
    font-size: 1.5rem;
    line-height: 0;
    margin-left: 2.2rem;
    display: none;

    @include mobile {
      display: block;
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.4rem;
  }

  &__city-title {
    font-size: 1.2rem;
    color: #6D7D95;
    font-weight: 500;

    @include mobile {
      font-size: 1rem;
      transform: translateY(-.1rem);
    }
  }

  &__close {
    line-height: 0;
  }

  &__phone {
    font-size: 1.8rem;
    color: $black;
    font-weight: bold;
    margin-bottom: .4rem;
  }

  &__phone-desc {
    font-size: 1.2rem;
    color: rgba($black, .5);
    margin-bottom: 2.1rem;
  }

  &__menu-btn {
    width: 100%;
    height: 4rem;
    margin-bottom: 3.3rem;
  }

  &__menu-list {
    display: flex;
    flex-wrap: wrap;
    margin: -1.5rem -.6rem;
  }

  &__menu-li {
    width: calc(100% / 2);
    padding: 1.5rem .6rem;

    &--services {
      width: 100%;
    }
  }

  &__services-list {
    display: flex;
    flex-wrap: wrap;
    margin: -1.5rem -.6rem;
  }

  &__services-li {
    width: calc(100% / 2);
    padding: 1.5rem .6rem;
  }

  &__link-list {
    margin: -.3rem;
  }

  &__link-li {
    padding: .3rem;
  }

  &__menu-header {
    font-size: 1.4rem;
    font-weight: bold;
    color: $blueText;
    margin-bottom: 1.6rem;
  }

  &__counter {
    color: #A6ACB4;
  }

  &__title {
    font-size: 1.3rem;
    font-weight: 500;
    color: #A1ADBD;
    margin-bottom: 1.4rem;
  }

  &__search-mobile {
    display: none;
    font-size: 1.3rem;
    line-height: 0;
    color: #15489D;

    @include mobile {
      display: block;
      margin-left: auto;
    }

    #{$self}--black & {
      color: $white;
    }
  }
}
