.app {
  background: #F0F5F9;
  margin-bottom: 10rem;

  @include mobile {
    margin-bottom: 4.9rem;
  }

  &__container {
    padding: 7rem 5.5rem 8.5rem;
    background: url(../img/css/app-bg.png) no-repeat;
    background-size: auto auto;
    background-position: right 5.5rem bottom;

    @include mobile {
      padding: 2.7rem 1.5rem;
      background-image: none;
    }
  }

  &__content {
    max-width: 62.5rem;

    @include mobile {
      margin: 0 auto;
    }
  }

  &__header {
    font-size: 3.5rem;
    line-height: 4.8rem;
    font-weight: bold;
    color: #15489d;
    margin-bottom: 1.8rem;

    @include mobile {
      font-size: 1.8rem;
      line-height: 2.5rem;
      margin-bottom: 1.2rem;
    }
  }

  &__desc {
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: 500;
    color: $blueText;
    margin-bottom: 4rem;
    max-width: 58.8rem;

    @include mobile {
      font-size: 1.2rem;
      line-height: 1.8rem;
      margin-bottom: 4rem;
      max-width: none;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -.6rem;
  }

  &__li {
    padding: .6rem;

    @include mobile {
      width: calc(100% / 2);
    }
  }

  &__link {
    display: block;
  }

  &__img {
    width: 100%;
    height: auto;
  }
}
