.equipment {
  $self: &;

  padding: 9.8rem 0 12.9rem;

  @include mobile {
    padding: 3.6rem 0 4.5rem;
  }

  &__list {
    display: flex;
    justify-content: center;
    margin-bottom: 7.9rem;

    @include mobile {
      margin-bottom: 4rem;
    }
  }

  &__btn-tab {
    color: #6D7D95;
    padding: 1.1rem 2.4rem;
    font-size: 1.6rem;
    font-weight: 600;
    background: transparent;
    border-radius: .5rem;
    border: .1rem solid transparent;
    transition: color .3s, border-color .3s;

    @include mobile {
      padding: .8rem;
      font-size: 1.2rem;
    }

    #{$self}__item.active & {
      border-color: #15489D;
      color: #15489D;
    }
  }

  &__list-card {
    margin: -1rem 0;

    @include mobile {
      margin: -.75rem 0;
    }
  }

  &__item-card {
    padding: 1rem 0;

    @include mobile {
      padding: .75rem 0;
    }
  }

  &__item-tabs {
    display: none;

    &.active {
      display: block;
    }
  }
}
