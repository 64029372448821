.search-popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 999;
  display: none;

  &.active {
    display: block;
  }

  &__search {
    padding: 1.5rem 1.5rem 1.8rem 1.5rem;
  }

  &__content {
    height: calc(100vh - 7.5rem);
    overflow: auto;
    overflow-x: hidden;
    margin-left: -1.5rem;
    width: calc(100% + 3rem);
    padding: 0 1.5rem;
  }

  &__head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__field-box {
    position: relative;
    flex: 1;
    margin-right: 1.5rem;
  }

  &__input {
    width: 100%;
    height: 4rem;
    border: 1px solid #1c61c8;
    border-radius: .4rem;
    padding: 0 1.3rem;
    font-size: 1.2rem;
    color: #15489D;

    &::placeholder {
      color: #15489D;
    }
  }

  &__submit {
    position: absolute;
    right: 1.3rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.4rem;
    color: #15489D;
    line-height: 0;
  }

  &__close {
    font-size: 1.5rem;
    color: $orange;
    line-height: 0;
  }

  &__list {
    margin: -1.2rem;
  }

  &__li {
    padding: 1.2rem;
  }

  &__item {
    font-size: 1rem;
    line-height: 1.6rem;
  }

  &__title {
    color: #15489d;
    font-weight: bold;
  }

  &__text {
    color: #6d7d95;
  }
}
