.about {
  $self: &;
  background: $blue;
  color: $white;
  position: relative;

  @include mobile {
    margin-bottom: 4.2rem;
  }

  &--business {
    @include mobile {
      margin-bottom: 0;
    }

    .search {
      border-bottom: 0;

      &__link {
        color: #fff;
      }

      &__input {
        background: $indexBusinessBlack;
        color: $white;
      }

      &__submit {
        color: #374350;

        @include hover {
          color: $orange;
        }
      }

      &__close {
        @include hover {
          color: $white;
        }
      }
    }
  }

  &--another {
    @include mobile {
      margin-bottom: 0;
    }
  }

  &--hide-main-slider {
    padding-bottom: 0.6rem;

    @include mobile {
      padding-bottom: 0;
    }
  }

  &__container {
    @include mobile {
      padding-left: 0;
      margin-left: -.4rem;
      width: calc(100% + .8rem);
    }

    #{$self}--hide-main-slider & {
      padding-bottom: 1.5rem;
    }
  }

  &--red {
    background: #C92A2B;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 2.4rem 0;
    margin-bottom: 1.8rem;
    position: relative;

    @include mobile {
      padding: .8rem 0;
      margin-left: 1.5rem;
      margin-bottom: 1.2rem;
    }

    #{$self}--another & {
      padding-bottom: 2rem;
      border-bottom: 0;
      margin-bottom: 0;

      @include mobile {
        padding-bottom: 1.2rem;
        margin-bottom: 1.2rem;
      }
    }

    #{$self}--hide-main-slider & {
      padding-bottom: 0;
      margin-bottom: 2rem;

      @include mobile {
        margin-bottom: 1rem;
      }
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: -100vw;
      width: 200vw;
      right: 0;
      height: 1px;
      background: rgba($white, .3);
      bottom: 0;

      #{$self}--another & {
        display: none;

        @include mobile {
          display: block;
        }
      }

      #{$self}--hide-main-slider & {
        display: none;
      }
    }
  }

  &__logo {
    display: block;
    width: 20rem;
    line-height: 0;

    @include mobile {
      width: 10rem;
    }
  }

  &__info-col {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 6rem;
    margin-right: auto;

    @include smallDesktop {
      margin-left: auto;
      margin-right: 0;
    }

    @include mobile {
      display: none;
    }
  }

  &__phone {
    font-size: 2rem;
    font-weight: bold;
    color: rgba($white, .8);
  }

  &__info-desc {
    font-size: 1.4rem;
    color: rgba($white, .5);
    margin-left: 2rem;
  }

  &__btn-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -.6rem;

    @include smallDesktop {
      width: 100%;
      margin-top: 2rem;
      justify-content: flex-end;
    }

    @include mobile {
      margin-left: auto;
      width: auto;
      margin-top: 0;
    }
  }

  &__btn-li {
    padding: .6rem;

    &:last-child {
      @include mobile {
        display: none;
      }
    }
  }

  &__btn {
    padding: 0 2.2rem;

    @include mobile {
      width: 9.8rem;
      height: 2.7rem;
      font-size: 1rem;
      padding: 0;
    }
  }

  &__triangle {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 14.2857vw;
    color: $white;
    transition: color .3s;

    @include smallDesktop {
      display: none;
    }

    #{$self}--another & {
      display: none;
    }

    #{$self}--business & {
      color: $indexBusinessBlack;
    }
  }

  &__search {
    @include mobile {
      width: 100%;
      border-radius: 0;
      width: calc(100% + 1.2rem);
      overflow: auto;
      padding: 0 1.5rem;

      .search {
        &__list {
          margin: 0;
          font-size: 0;
          white-space: nowrap;
          display: block;
        }

        &__li {
          padding: 0;
          display: inline-block;
          margin-left: 2rem;

          &:first-child {
            margin-left: 0;
          }
        }

        &__submit {
          display: none;
        }
      }
    }

    #{$self}--another & {
      margin-bottom: .9rem;

      @include mobile {
        margin-left: 1.5rem;
        width: calc(100% - 1.5rem);
        border-radius: .4rem;
      }
    }

    #{$self}--hide-main-slider & {
      margin-bottom: 0;
    }

    #{$self}--business & {
      background: $indexBusinessBlack;
    }
  }
}
