.btn {
  $self: &;

  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4.4rem;
  padding: 0 3.7rem;
  background: $white;
  font-size: 1.5rem;
  font-weight: 500;
  color: $blueText;
  border-radius: .4rem;
  transition: color .3s, background .3s, border-color .3s;

  @include hover {
    background: $orange;
    color: $white;
  }

  &--orange {
    background: $orange;
    color: #FFEBC6;
  }

  &--trans-orange {
    border: 1px solid $orange;
    color: $orange;

    @include hover {
      background: #FFEBC6;
      border-color: #FFEBC6;
      color: $orange;
    }
  }

  &--white-pale {
    background: rgba(#fff, .3);
    color: #fff;

    @include hover {
      background: $orange;
    }
  }

  &--blue {
    background: #15489D;
    border-color: #15489D;
    color: $white;
  }

  &--white {
    background: $white;
    color: #15489d;
  }

  &--red-pale {
    background: #FFEBC6;
    border-color: #FFEBC6;
    color: $orange;
  }

  &--light-brown {
    background: #FFF9EE;
    border-color: #FFF9EE;
    color: $orange;
  }

  &--trans-grey {
    background: none;
    border: 1px solid #3A5565;
    color: #3A5565;

    @include hover {
      background: none;
      border-color: $orange;
      color: $orange;
    }
  }

  &__icon {
    &--absolute-left {
      position: absolute;
      left: 1.4rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
