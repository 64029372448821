.label-field {
  position: relative;

  &__input {
    font-size: 1.4rem;
    color: #5e6976;
    padding: 0 1.7rem;
    height: 5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include mobile {
      padding: 0 .9rem;
      font-size: 1.2rem;
      height: 3.7rem;
    }

    &::placeholder {
      color: #5e6976;
      opacity: 1!important;
    }
  }
}
