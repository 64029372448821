.modal {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: stretch;

  @include mobile {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    overflow: auto;
  }

  &__content-col {
    flex: 1;
    padding: 6.5rem 6.5rem 6.5rem 6.5rem;

    @include mobile {
      width: 100%;
      padding: 2rem;
    }
  }

  &__decor-col {
    width: 25.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @include mobile {
      width: 100%;
      padding: 2rem 0;
      display: block;
      text-align: center;
    }
  }

  &__icon {
    width: 10.6rem;

    @include mobile {
      margin: 0 auto;
      display: block;
    }
  }

  &__text {
    padding-bottom: 2rem;
    color: #6D7D95;
  }

  &__link {
    display: inline-block;
    padding-bottom: 2rem;
    color: #6D7D95;

    @include hover {
      color: $blue;
    }
  }

  &__count {
    text-align: center;
    margin: 0 auto;
    padding: 1rem 2.7rem;
    font-size: 1.2rem;
    background-color: #E1654F;

    @include mobile {
      margin: 0 auto;
    }
  }

  &__close {
    max-width: 8.3rem;
  }

  &__bottom {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
