.join {
  width: 60%;
  margin: 0 auto 15rem auto;

  @include mobile {
    width: auto;
    margin-bottom: 2rem;
  }

  &__head-title {
    margin-bottom: 3rem;

    @include mobile {
      margin-bottom: 2rem;
    }
  }

  &__desc {
    font-size: 1.4rem;
    line-height: 2.6rem;
    color: #345565;
    text-align: center;

    @include mobile {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }

    &--bold {
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 3rem;

      @include mobile {
        margin-bottom: 2rem;
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }

  &__btn {
    width: 18.7rem;
    height: 4.4rem;
    margin: 0 auto;
    margin-top: 6.8rem;
    padding: 0;

    @include mobile {
      margin-top: 2.2rem;
    }
  }
}
