.search {
  $self: &;
  background: $white;
  color: $black;
  border-radius: .4rem .4rem 0 0;
  border-bottom: 1px solid #C8D5E8;
  padding: 0 .7rem 0 3.3rem;
  height: 5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @include mobile {
    height: 4rem;
  }

  &--extend {
    border-radius: .4rem;
    border-bottom: none;
    height: 5rem;
    padding: 0;
    max-width: 60rem;

    @include mobile {
      height: 5rem;
    }
  }

  &__default {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    opacity: 1;
    transition: opacity .3s;

    #{$self}.active & {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__list-col {
    flex: 1;
    padding-right: 2rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -1.4rem;

    @include smallDesktop {
      margin: -.6rem;
    }
  }

  &__li {
    padding: 1.4rem;

    @include smallDesktop {
      padding: .6rem;
    }
  }

  &__link {
    font-size: 1.6rem;
    color: $blueDark;
    transition: color .3s;

    @include hover {
      color: $orange;
    }

    @include smallDesktop {
      font-size: 1.3rem;
    }
  }

  &__submit {
    font-size: 3.6rem;
    line-height: 0;
    color: $blue;
    transition: transform .3s, color .3s;

    #{$self}.active & {
      transform: translateX(-5rem);
    }

    @include hover {
      color: $orange;
    }

    #{$self}--extend & {
      margin-left: auto;
    }
  }

  &__active {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    transition: opacity .3s;
    padding-right: .7rem;

    #{$self}.active & {
      opacity: 1;
      pointer-events: auto;
    }

    #{$self}--extend & {
      opacity: 1;
      pointer-events: auto;
      position: static;
    }
  }

  &__input {
    flex: 1;
    font-size: 1.8rem;
    line-height: 1.8;
    padding-left: 2rem;
    margin-right: 6rem;
    color: #6D7D95;

    @include mobile {
      font-size: 1.6rem;
      padding-left: 1.3rem;
    }

    &::placeholder {
      color: #cecece;
    }

    #{$self}--extend & {
      margin-right: 0;
      padding-right: 2rem;
      flex: none;
      width: calc(100% - 4rem);
    }
  }

  &__close {
    position: absolute;
    right: 1.8rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.4rem;
    line-height: 0;
    color: $orange;
    transition: color .3s;

    @include hover {
      color: $black;
    }
  }
}
