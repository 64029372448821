.lb-nav a.lb-next {
  display: inline-block !important;
  background-image: url(../svg_icons/circle-arrow-right-white.svg);
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 10rem;
  width: 50%;
  height: 100%;
}

.lb-nav a.lb-prev {
  display: inline-block !important;
  background-image: url(../svg_icons/circle-arrow-left-white.svg);
  background-repeat: no-repeat;
  background-position: 10% center;
  background-size: 10rem;
  width: 50%;
  height: 100%;
}

.lb-dataContainer {
  display: none !important;
}

.lb-outerContainer {
  min-width: 60rem !important;
  min-height: 44rem !important;
  overflow: visible !important;

  @include mobile {
    min-width: auto !important;
    min-height: auto !important;
  }

  &::before {
    content: "";
    position: absolute;
    height: calc(100% + 16rem);
    z-index: -1;
    width: calc(100% + 6rem);
    box-sizing: content-box;
    background-color: #fff;
    bottom: -13rem;
    border-radius: .8rem;
    left: -3rem;
    right: 0;
    pointer-events: none;

    @include mobile {
      height: 100%;
      width: 100%;
      left: 0;
      bottom: -5.9rem;
    }

    @include hover {
      background-color: red;
    }
  }

  &::after {
    content: "Закрыть";
    position: absolute;
    z-index: 9999;
    width: 16rem;
    height: 5.2rem;
    background-color: #15489D;
    border-radius: .4rem;
    bottom: -9.5rem;
    right: 3rem;
    margin: 0 auto;
    font-size: 2rem;
    line-height: 5.7rem;
    color: $white;
    pointer-events: none;

    @include mobile {
      width: 11.5rem;
      height: 3rem;
      bottom: -4.4rem;
      right: 1.9rem;
      font-size: 1.4rem;
      line-height: 3.7rem;
      color: #fff;
      pointer-events: none;
    }
  }
}

.lb-image {
  min-width: 60rem !important;
  min-height: 44rem !important;

  @include mobile {
    min-width: auto !important;
    min-height: auto !important;
    padding: 0;
    border: none !important;
  }
}

.lb-cancel {
  background: url(../img/content/loading.gif) no-repeat;
}





