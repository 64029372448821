.contacts-callback {
  background-color: #EBEFF5;
  padding: 42.1rem 0 0 0;

  &__container {
    padding: 0 1.5rem 0 1.5rem;

    @include mobile {
      padding: 0;
    }
  }

  @include mobile {
    padding: 0;
  }
}
