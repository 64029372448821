.news-card {
  width: 100%;
  height: 100%;
  color: $black;
  display: flex;
  flex-direction: column;

  &__img-box {
    position: relative;
    height: 32.7rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-size: 0;
    border-radius: .8rem .8rem 0 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include smallDesktop {
      height: 26.7rem;
    }

    @include mobile {
      height: 14rem;
    }
  }

  &__date {
    position: absolute;
    left: 3rem;
    bottom: 3rem;

    @include mobile {
      left: 1rem;
      bottom: 1rem;
    }
  }

  &__content {
    background: #F0F5F9;
    padding: 1.8rem 2.9rem 3.5rem;
    border-radius: 0 0 .8rem .8rem;
    flex: 1;

    @include mobile {
      padding: 1.7rem 2rem 1.4rem;
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 2.7rem;
    font-weight: 500;
    margin-bottom: 1.4rem;

    @include mobile {
      font-size: 1.8rem;
      line-height: 2.5rem;
      margin-bottom: .6rem;
    }

    @include hover {
      color: $red;
    }

    &:active {
      opacity: 0.6;
    }
  }

  &__desc {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #3a5565;

    @include mobile {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
}
