.date {
  display: inline-block;
  border-radius: .2rem;
  align-items: center;
  background: $red;
  padding: 0.1rem 0.2rem;
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: $white;
}
