.contacts-form {
  position: relative;
  z-index: 1;

  &__container {
    position: absolute;
    display: block;
    top: -42.1rem;
    width: 100%;
    padding: 5.9rem 22.4rem 8.9rem 9.4rem;
    margin: 0 auto;
    background-color: #15489D;

    @include mobile {
      position: inherit;
      padding: 3.8rem 1.4rem 5.2rem 1.4rem;
      top: 0;
    }

    @media (min-width: 980px) and (max-width: 1070px) {
      padding: 5.9rem 9.4rem 8.9rem 9.4rem;
    }
  }

  .head-title {
    margin: 0;
    text-align: left;

    &__desc {
      padding: .5rem 0 3.8rem 0;

      @include mobile {
        margin: 0;
        padding: .4rem 0 .1rem 0;

        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }

  &__fieldset {
    padding: 0;
    border: none;
  }

  &__fieldset-wrapper {
    padding: 2.6rem 0 .9rem 0;

    @include mobile {
      padding: 2.6rem 0 0 0;
    }
  }

  &__label {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 1.4rem 0;

    @include mobile {
      padding: 0 0 2rem 0;
    }
  }

  &__submit-block {
    padding: 0 0 0 11.5rem;

    @include mobile {
      padding: 0;
    }

    @media (min-width: 980px) and (max-width: 1070px) {
      padding: 0;
    }
  }

  &__input-text {
    width: 13%;

   
    font-size: 1.6rem;
    color: $white;
    line-height: 4.7rem;

    @include mobile {
      width: 100%;
      font-size: 1.4rem;
      line-height: 2rem;
    }

    @media (min-width: 980px) and (max-width: 1070px) {
      width: 100%;
    }
  }

  &__input {
    padding: 1.3rem;
    border-radius: .4rem;
    line-height: 2rem;

    @include mobile {
      line-height: 1.7rem;
    }
  }

  &__input:not(&__input--textarea) {
    width: 37%;

    @include mobile {
      width: 100%;
    }

    @media (min-width: 980px) and (max-width: 1070px) {
      width: 100%;
    }
  }

  &__input--textarea {
    width: 86%;
    min-height: 13rem;
    resize: none;
    border: none;

    @include mobile {
      width: 100%;
      min-height: 10.4rem;
    }

    @media (min-width: 980px) and (max-width: 1070px) {
      width: 100%;
    }
  }

  .btn {
    display: block;
    width: 32%;
    padding: 0 0 0 0;

    @include mobile {
      width: 100%;
    }
  }

  &__input + .parsley-errors-list {
    padding-left: 13%;
    font-size: 1.3rem;

    @include mobile {
      padding-left: 0;
    }

    @media (min-width: 980px) and (max-width: 1070px) {
      padding-left: 0;
    }
  }
}
