.business-advantages {
  background: #F2F2F2;
  padding: 10rem 0 19rem;

  @include mobile {
    padding: 4.7rem 0 7.4rem;
  }

  &__head-title {
    margin-bottom: 7.6rem;

    @include mobile {
      margin-bottom: 3.2rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -1.9rem;

    @include mobile {
      margin: -1rem;
    }
  }

  &__li {
    padding: 1.9rem;
    width: calc(100% / 3);

    @include mobile {
      width: 100%;
      padding: 1rem;
    }
  }

  &__item {

  }

  &__card-list {
    margin: -1.9rem;

    @include mobile {
      margin: -1rem;
    }
  }

  &__card-li {
    padding: 1.9rem;

    @include mobile {
      padding: 1rem;
    }
  }

  &__card-business {
    @include mobile {
      max-width: 50rem;
      margin: 0 auto;
    }
  }
}
