.head-title {
  $self: &;
  margin-bottom: 9.2rem;
  text-align: center;

  @include mobile {
    margin-bottom: 4.3rem;
  }

  &__title {
    @include raleway;
    font-weight: 300;
    font-size: 4.8rem;
    line-height: 8.1rem;
    color: $black;
    text-transform: uppercase;

    @include mobile {
      font-size: 2.8rem;
      line-height: 3.6rem;
    }

    #{$self}--white & {
      color: $white;
    }
  }

  &__desc {
    font-size: 1.6rem;
    font-weight: 500;
    color: $blueText;
    margin-top: .1rem;

    @include mobile {
      font-size: 1rem;
      margin-top: .8rem;
    }

    #{$self}--white & {
      color: $white;
    }
  }
}
