.build {
  $self: &;
  // circle global size
  $circleGlobalWidth: 28.8rem;

  // circle--inet
  $circleInetSize: 28.8rem;
  $circleInetWidth: 68.2899rem;
  $circleInetPosition: -32%;

  // circle--tv
  $circleTvSize: 24.6rem;
  $circleTvWidth: 58rem;
  $circleTvPosition: -7.86rem;

  // circle--inet-tv
  $circleInetTvSize: 21rem;
  $circleInetTvWidth: 49rem;
  $circleInetTvPosition: -32%;

  padding-bottom: 9.3rem;
  border-bottom: 1px solid #15489d;

  @include mobile {
    padding-bottom: 7rem;
    margin-bottom: 2.8rem;
  }

  &__head {
    margin-bottom: 7rem;
    padding: 0 5rem;

    @include smallDesktop {
      margin-bottom: 0;
    }

    @include mobile {
      margin-bottom: 4rem;
      padding: 0;
    }
  }

  &__option-list {
    display: flex;
    flex-wrap: wrap;
    margin: -2.5rem;

    @include mobile {
      margin: -1.0rem;
    }
  }

  &__option-li {
    width: calc(100% / 3);
    padding: 2.5rem;

    @include mobile {
      width: 100%;
      padding: 1.0rem;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 5rem;

    @include smallDesktop {
      justify-content: flex-start;
      margin-top: 5rem;
    }

    @include mobile {
      margin-top: 0;
      padding-left: 0;
    }
  }

  &__total-col {
    @include smallDesktop {
      margin-left: 2rem;
    }

    @include mobile {
      margin-left: 0;
      width: 100%;
    }
  }

  &__total-list {
    border: 1px solid #dadada;
    border-radius: .8rem;

    @include mobile {
      margin: 0;
    }
  }

  &__total-li {
    position: relative;
    padding: 1.8rem;

    @include mobile {
      padding: 1.3rem 1rem;
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 100%;
      right: 0;
      height: 1px;
      background: #dadada;
    }

    &:last-child:after {
      display: none;
    }
  }

  &__total-card {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-left: 3.2rem;
    font-size: 1.4rem;
    color: $blueText;

    @include mobile {
      padding-left: 2rem;
      font-size: 1rem;
    }

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1rem;
      height: 1rem;
      background: $orange;
      border-radius: 50%;

      @include mobile {
        left: 0;
      }

      #{$self}__total-li--tv & {
        background: #6586BC;
      }

      #{$self}__total-li--inet-tv & {
        background: #C4DAFF;
      }
    }

    &--circle {
      padding-left: 0;
      width: 14.4rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      border-radius: .6rem;
      border: 1px solid $orange;
      background: $white;
      z-index: 10;
      padding: 1rem;
      position: fixed;
      left: 0;
      top: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s;

      &.active {
        opacity: 1;
        pointer-events: auto;
      }

      &:before {
        display: none;
      }
    }

    &--inet {

    }

    &--tv {
      border-color: #2E62B6;
    }

    &--inet-tv {
      border-color: #ABC3ED;
    }
  }

  &__circle-hover {
    position: absolute;
    left: 47%;
    top: 47%;
    transform: translate(-50%, -50%);
    z-index: 5;
    width: 28rem;
    height: 28rem;
    opacity: 0;
  }

  &__summ-col {
    text-align: center;
    min-width: 33.6rem;

    @include smallDesktop {
      width: 100%;
      margin-top: 3rem;
    }

    @include mobile {
      min-width: auto;
      margin-top: 4.3rem;
    }
  }

  &__desc {
    font-size: 1rem;
    color: $blueText;
    margin-bottom: .7rem;
  }

  &__price {
    font-size: 3.6rem;
    font-weight: 300;
    line-height: 4.9rem;
    color: $black;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 4rem;
  }

  &__btn {
    width: 18.7rem;
    height: 5rem;
    margin: 0 auto;

    @include mobile {
      height: 4rem;
    }
  }

  &__circle-col {
    width: $circleGlobalWidth;
    height: $circleGlobalWidth;
    position: relative;

    @include desktop {
      margin-left: 8rem;
    }

    @include mobile {
      display: none;
    }
  }

  &__circle-item {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    &--inet {
      width: $circleInetSize;
      height: $circleInetSize;
    }

    &--tv {
      width: $circleTvSize;
      height: $circleTvSize;
    }

    &--inet-tv {
      width: $circleInetTvSize;
      height: $circleInetTvSize;
    }
  }

  &__circle, &__circle-backside {
    position: absolute;

    #{$self}__circle-item--inet & {
      width: $circleInetWidth;
      left: $circleInetPosition;
      top: $circleInetPosition;
    }

    #{$self}__circle-item--tv & {
      width: $circleTvWidth;
      left: $circleTvPosition;
      top: $circleTvPosition;
    }

    #{$self}__circle-item--inet-tv & {
      width: $circleInetTvWidth;
      left: $circleInetTvPosition;
      top: $circleInetTvPosition;
    }
  }

  &__circle {
    z-index: 2;
  }

  &__circle-icon {

  }

  &__circle-backside {
    z-index: 1;
  }

  &__circle-icon {
    transition: .4s;
  }

  &__circle-popup {
    position: absolute;
    left: 47%;
    top: 44%;
    transform: translate(-50%, -50%);
    width: 16.6rem;
    text-align: center;
  }

  &__popup-desc {
    font-size: 1rem;
    color: $blueText;
    margin-bottom: .7rem;
  }

  &__popup-price {
    font-size: 3.2rem;
    font-weight: 300;
    color: $blueText;
  }

  &__range-slider {

    @include mobile {
      margin-bottom: 0;
    }
  }
}
