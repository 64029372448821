.checkbox {
  padding: 0 0 3.5rem 0;

  @include mobile {
    padding: 0 0 2.8rem 0;
  }

  &__label {
    display: flex;
    align-items: center;
    padding: 0;

    @include mobile {
      padding: 0;
    }
  }

  &__input {
    margin-right: 1.3rem;
    width: 2rem;
    height: 2rem;
  }

  &__text {
    position: relative;
    padding-left: 3.1rem;


    font-size: 1.6rem;
    color: $white;
    line-height: 2.6rem;

    @include mobile {
      font-size: 1.2rem;
      line-height: 2.6rem;
    }
  }

  &__link {
    margin-left: .5rem;
    border-bottom: .1rem solid $white;
    transition: color .3s, border-color .3s;


    font-size: 1.6rem;
    color: $white;
    line-height: 2.6rem;

    @include mobile {
      font-size: 1.2rem;
      line-height: 2.6rem;
    }

    @include hover {
      color: $orange;
      border-color: $orange;
    }
  }

  &__input + &__text::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: .4rem;
    left: 0;
    width: 1.9rem;
    height: 1.9rem;
    margin-right: 1.7rem;
    cursor: pointer;

    border-radius: .4rem;
    border: solid .1rem #0191FF;
    background-color: #0191FF;
  }

  &__input:focus + &__text::before {
    outline: .2rem solid #7f5faf;
  }

  &__input:checked + &__text::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: .8rem;
    left: .3rem;
    width: 1.3rem;
    height: 1.1rem;
    cursor: pointer;

    background-image: url("../svg_icons/white_check.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &__label + .parsley-errors-list {
    width: 100%;
    color: #C92A2B;
    font-size: 1.3rem;
    padding-left: 0;
    padding-top: .7rem;
  }
}
