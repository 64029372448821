.faq {
  &__container {
    padding-bottom: 17.4rem;
    padding-top: 10.3rem;

    @include mobile {
      padding-top: 3.2rem;
      padding-bottom: 4.6rem;
    }
  }

  .head-title {
    margin-bottom: 7.5rem;

    @include mobile {
      margin-bottom: 2.8rem;
    }
  }

  &__list {
    padding-bottom: 2.9rem;

    @include mobile {
      padding-bottom: 1.6rem;
    }
  }

  &__list-item {
    padding-bottom: 2.1rem;

    @include mobile {
      padding-bottom: 1.5rem;
    }
  }

  .faq__list-item.active {
    .faq-card {
      background-color: rgba(109, 125, 149, 0.1);
    }

    .faq-card__open {
      display: block;
    }

    .js-accordion-btn {
      background: $orange;
      color: #FFEBC6;
    }

    .faq-card__button-text--open {
      display: none;
    }

    .faq-card__button-text--close {
      display: block;
    }
  }

  &__more-button {
    display: block;
    margin: 0 auto;
    width: 23%;
    min-height: 5.1rem;
    border: 1px solid #3A5565;
    font-size: 1.6rem;
    color: #3A5565;
    text-align: center;

    @include mobile {
      width: 100%;
      min-height: auto;
    }

    @include hover {
      color: $white;
      background-color: #3A5565;
    }

    &:active {
      opacity: 0.6;
    }
  }
}
