.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background: rgba($black, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  &__content {
    width: 100%;
    max-width: 88rem;
    background: $white;
    border-radius: 0.8rem;
    padding: 4.6rem 7.2rem 0;
    height: calc(100vh - 20rem);
    overflow: auto;
    overflow-x: hidden;
    position: relative;

    @include mobile {
      height: 100%;
      padding: 2rem 1.5rem 0;
      border-radius: 0;
    }

    &:after {
      display: block;
      content: "";
      width: 100%;
      height: 7.7rem;
      background: none;

      @include mobile {
        height: 2rem;
      }
    }
  }

  &__modal {
    padding: 0;

    &:after {
      display: none;
    }
  }

  &--request {
    .modal__decor-col {
      background-color: rgba(74, 131, 166, 0.157);

      @include mobile {
        display: none;
      }
    }

    .popup__modal {
      height: 67.4rem;

      @include mobile {
        height: 51.5rem;
      }
    }
  }

  &--channel {
    .modal__content-col {
      background-color: rgba(74, 131, 166, 0.157);
    }

    .modal__icon {
      @include mobile {
        width: 16rem;
        height: 16rem;
      }
    }

    .popup__modal {
      height: auto;
      max-height: calc(100vh - 10rem);

      @include mobile {
        max-height: calc(100vh - 5rem);
      }
    }
  }

  &--thanks {
    .modal__decor-col {
      background-color: rgba(74, 131, 166, 0.157);

      @include mobile {
        padding: 4rem 0;
      }
    }

    .popup__modal {
      height: 42.3rem;

      @include mobile {
        height: 46rem;
      }
    }

    .modal__bottom {
      margin-top: 7rem;

      @include mobile {
        margin-top: 5.5rem;
      }
    }
  }
}
