.select {
  $self: &;
  position: relative;
  z-index: 999;

  &__placeholder {
    position: relative;
    display: block;
    width: 100%;

    #{$self}--default & {
      font-size: 1.4rem;
      color: #5E6976;
      background: $white;
      padding-left: 1.7rem;
      padding-right: 4rem;
      height: 5rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @include mobile {
        padding-left: .9rem;
        font-size: 1.2rem;
        height: 3.7rem;
      }
    }

    #{$self}--city & {
      font-size: 1.2rem;
      color: $orange;
      padding-right: 1.3rem;
    }

    #{$self}.active & {
      color: $orange;
    }
  }

  &__placeholder-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__icon-wrap {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;

    #{$self}--default & {
      font-size: 1.2rem;
      color: $orange;
      right: 1.8rem;
      transform: translateY(-50%);

      @include mobile {
        right: 1.3rem;
      }
    }

    #{$self}--city & {
      color: $orange;
      font-size: .8rem;
      transform: translateY(calc(-50% + .2rem));
    }
  }

  &__icon {
    transition: transform .3s;

    #{$self}.active & {
      transform: rotate(-180deg);
    }
  }

  &__dropdown {
    background: $white;
    border-radius: .4rem;
    padding: 1.2rem 1.4rem 1.2rem;
    position: absolute;
    left: 50%;
    top: calc(100% + .4rem);
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    z-index: 20;

    #{$self}.active & {
      opacity: 1;
      pointer-events: auto;
    }

    #{$self}--default & {
      left: 0;
      right: 0;
      transform: none;
      top: calc(100% + .1rem);
      padding-top: 1rem;
      padding-bottom: 1rem;
      box-shadow: none;
    }
  }

  &__list {
    margin: -.5rem;

    #{$self}--default & {
      margin: -.25rem;
    }
  }

  &__li {
    padding: .5rem;

    #{$self}--default & {
      padding: .25rem;
    }
  }

  &__option {
    font-size: 1.2rem;
    font-weight: 500;
    color: $blueText;
    cursor: pointer;
    transition: color .3s;

    #{$self}--default & {
      font-size: 1.4rem;
      color: #5E6976;
    }

    @include hover {
      color: $orange;
    }
  }
}
