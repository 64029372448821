.contacts-text {
  background-color: $lightBlueBackground;

  &__container {
    padding: 4.8rem 3.9rem 4.4rem 1.6rem;

    @include smallDesktop {
      padding: 3rem 1.5rem 5.1rem 1.5rem;
    }
  }

  .head-title {
    margin: 0;
    padding-bottom: 1.4rem;
    text-align: left;

    @include mobile {
      padding-bottom: .8rem;
    }
  }
}
