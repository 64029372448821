.faq-card {
  padding: 2.8rem 3rem 2.9rem 2.8rem;
  border: 1px solid #DADADA;
  border-radius: .4rem;
  background-color: inherit;
  transition: background-color .3s;

  @include mobile {
    padding: 1.7rem 1.9rem 1.9rem 1.9rem;
  }

  &__close {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
  }

  &__open {
    display: none;
    padding: 2.4rem 0 .9rem 0;

    @include mobile {
      padding: 0 0 1.9rem 0;
    }
  }

  .faq__list-item.active {
    .faq-card {
      background-color: #DADADA;
    }

    .faq-card__open {
      display: block;
    }

    .js-accordion-btn {
      background: $orange;
      color: #FFEBC6;
    }

    .faq-card__button-text--open {
      display: none;
    }

    .faq-card__button-text--close {
      display: block;
    }
  }

  &__button-text--close {
    display: none;
  }

  &__button-text--open {
    display: block;
  }

  &__btn {
    display: flex;
    min-height: 5.1rem;
  }

  .js-accordion-btn {
    width: 17%;
    max-width: 18.7rem;
    margin-top: .5rem;

    @include mobile {
      min-height: auto;
      height: 4rem;
      width: 64%;
      order: 1;
    }
  }

  &__question-text {
    max-width: 70%;

    font-size: 1.8rem;
    color: $black;

    @include mobile {
      padding-bottom: 1.3rem;
      font-size: 1.6rem;
      max-width: none;
      width: 100%;
    }
  }

  &__user-text,
  &__author-text {

    font-size: 1.4rem;
    color: #3A5565;
    line-height: 2.4rem;

    @include mobile {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }

  &__author-text {
    padding: 1.9rem 0 0 0;

    @include mobile {
      padding: 1.3rem 0 0 0;
    }
  }

  &__author-description {
    width: 100%;
    display: inline-block;
  }

  &__user-name,
  &__author-description {

    font-size: 1.4rem;
    color: $black;
    line-height: 2.4rem;

    @include mobile {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
}
