.connect {
  padding-bottom: 7.7rem;
  background-size: 100% auto;
  background-position: center bottom;
  padding-top: 15rem;
  position: relative;

  @include mobile {
    padding-top: 0;
    padding-bottom: 6.3rem;
  }

  &__bg-col {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: no-repeat;
    background-position: center bottom;
    opacity: 0;
    transition: opacity .3s;
    background-size: 100% auto;

    @include mobile {
      background-size: cover;
    }

    &.active {
      opacity: 1;
    }
  }

  &__head-title {
    position: relative;
    z-index: 5;
  }

  &__form {
    max-width: 92rem;
    margin: 0 auto;
    background: #1A65CE;
    border-top: 4px solid $orange;
    padding: 7rem 0 7.3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 0 0 .6rem .6rem;
    margin-bottom: 7.5rem;
    position: relative;
    z-index: 5;
    box-shadow: 0px 12px 24px rgba(31, 65, 133, 0.290865);

    @include mobile {
      padding: 3.7rem 1.5rem 4.2rem;
      margin-bottom: 4rem;
    }
  }

  &__field-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -.05rem;

    @include mobile {
      width: 100%;
    }
  }

  &__field-li  {
    padding: .05rem;

    &--city {
      @include mobile {
        width: 100%;
      }

      .select__placeholder {
        border-radius: .4rem 0 0 .4rem;

        @include mobile {
          border-radius: .4rem .4rem 0 0;
        }
      }
    }

    &--street {
      @include mobile {
        flex: 1;
      }

      .label-field__input {
        @include mobile {
          border-radius: 0 0 0 .4rem;
        }
      }
    }

    &--house {
      @include mobile {
        width: 5.9rem;
      }

      .label-field__input {
        width: 8rem;
        border-radius: 0 .4rem .4rem 0;

        @include mobile {
          border-radius: 0 0 .4rem 0;
          text-align: center;
        }
      }
    }
  }

  &__btn {
    width: 17.3rem;
    height: 5rem;
    margin-left: 1.5rem;

    @include mobile {
      width: 100%;
      height: 4rem;
      margin-left: 0;
      margin-top: 1rem;
    }
  }

  &__label-field {
    &--select {
      width: 22rem;

      @include mobile {
        width: 100%;
      }
    }

    .label-field {
      &__input {
        @include mobile {
          width: 100%;
        }
      }
    }
  }

  &__btn-map {
    width: 27.6rem;
    height: 7.4rem;
    border-radius: .8rem;
    background: $white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    color: $orange;
    box-shadow: 0 6px 27px 0 rgba($black, .3);
    margin: 0 auto;
    z-index: 10;
    position: relative;
    border: 1px solid #DADADA;
    box-shadow: 0px 6px 27px rgba(0, 0, 0, 0.1);
    transition: background .3s;

    @include hover {
      background: $orange;
      color: $white;
    }

    @include mobile {
      width: 19.6rem;
      height: 5rem;
      font-size: 1.4rem;
    }
  }

  &__btn-map-text {
    border-bottom: 1px dashed $orange;
  }
}
