.range-slider {
  $self: &;

  &__header {
    font-size: 1.4rem;
    line-height: 1.9rem;
    font-weight: bold;
    color: #6d7d95;
    margin-bottom: 1rem;
  }

  &__content-col {
    background: #EBEFF5;
    border-radius: .4rem;
    padding: 1.8rem 0 0;
  }

  &__option-col {
    margin-bottom: 1.1rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    position: relative;
  }

  &__li {

  }

  &__item {
    font-size: 1.4rem;
    line-height: 1.9rem;
    font-weight: bold;
    color: rgba($black, .5);
    transition: color .3s;
    width: 2.4rem;
    text-align: center;
    cursor: pointer;

    @include hover {
      color: $orange;
    }

    #{$self}__li.active & {
      color: $orange;
    }
  }

  &__bar {
    position: relative;
    height: 1.7rem;
    transform: translateY(.7rem);

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: .4rem;
      background: $orange;
      border-radius: 0 0 .4rem .4rem;
    }
  }

  &__btn {
    position: absolute;
    left: 0rem;
    top: 50%;
    transform: translate(23%, -50%);
    width: 1.7rem;
    height: 1.7rem;
    background: $white;
    border: .3rem solid $orange;
    border-radius: 50%;
    z-index: 5;
    transition: left .3s, transform .2s;

    &.active {
      transform: translate(23%, -50%) scale3d(1.2, 1.2, 1.2);
    }
  }
}
