.contact-info {
  display: flex;
  flex-wrap: wrap;
  padding: 1.2rem 0 0 0;

  &__block {
    width: 31%;

    &:nth-child(2) {
      width: 29%;
    }

    &:nth-child(3) {
      width: 40%;
    }

    &:nth-child(2) .contact-info__description {
      color: #E1654F;
    }

    &:nth-child(3) .contact-info__list-item:nth-child(1) {
      padding-bottom: 2.5rem;
    }

    @include mobile {
      width: 100%;
      padding-bottom: 4rem;

      &:nth-child(2),
      &:nth-child(3) {
        width: 100%;
      }
    }

    @media (min-width: 650px) and (max-width: 1070px) {
      max-width: 60%;

      &:nth-child(1),
      &:nth-child(2) {
        width: 50%;
        padding-bottom: 3rem;
      }

      &:nth-child(3) {
        width: 100%;
      }
    }
  }

  &__block-headline {
    padding: 0 0 2.2rem 0;

   
    font-size: 2rem;
    color: $black;

    @include mobile {
      padding: 0 0 1.4rem 0;
      font-size: 1.4rem;
    }
  }

  &__list-item {
    font-size: 1rem;
    line-height: 1.6rem;
  }

  &__text {
   
    font-size: 1.4rem;
    font-weight: bold;
    color: #3A5565;
    line-height: 2.6rem;

    @include mobile {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }

  &__description {
   
    font-size: 1.4rem;
    color: #3A5565;
    line-height: 2.6rem;

    @include mobile {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
}
