.package {
  $self: &;

  @include mobile {
    margin-bottom: 3.7rem;
  }

  &__container {
    @include mobile {
      padding-right: 0;
    }
  }

  &__top {
    padding-top: 9rem;
    padding-bottom: 21.6rem;
    background: #eef0f2;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 100%;

    @include smallDesktop {
      background-image: none!important;
    }

    @include mobile {
      padding-top: 3.9rem;
      padding-bottom: 11.2rem;
    }
  }

  &__head-title {
    margin: 0;
    width: 82rem;
    text-align: left;
  }

  .head-title__title {
    font-size: 4.8rem;
    line-height: 8.1rem;

    @include mobile {
      font-size: 2.8rem;
    }
  }

  .head-title__desc {
    color: #3A5565;
    font-size: 1.4rem;
    line-height: 2.4rem;

    @include mobile {
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }

  &__bottom {
    margin-top: -13rem;

    @include mobile {
      margin-top: -9.9rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    @include mobile {
      margin: 0;
      flex-wrap: none;
      overflow: auto;
      overflow-y: hidden;
      flex-wrap: nowrap;
    }
  }

  &__item {
    padding: 0 1rem;
    width: calc(100% / 3);

    @include mobile {
      padding: 0;
      margin-left: 2rem;
      min-width: 23.7rem;
      display: inline-block;

      &:first-child {
        margin-left: 0;
      }
    }

    &--speed, &--wifi, &--minus {
      .card-package {
        &__img-node {
          width: 8.5rem;
          height: 8.5rem;
        }
      }
    }
  }
}
