.feedback {
  $self: &;
  background: $orange;
  color: $white;
  padding: 3.5rem 0;
  margin-bottom: 14rem;

  @include mobile {
    padding: 4rem 0;
    margin-bottom: 4.3rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include mobile {
      display: block;
    }
  }

  &__img-col {
    width: 64.8rem;
    height: auto;

    @include smallDesktop {
      width: 50rem;
    }

    @include mobile {
      width: 100%;
      max-width: 50rem;
      margin: 0 auto;
      margin-bottom: 5.4rem;
    }
  }

  &__img {

  }

  &__content-col {
    flex: 1;
    padding-left: 10rem;

    @include smallDesktop {
      padding-left: 5rem;
    }

    @include mobile {
      padding-left: 0;
      flex: none;
    }
  }

  &__title {
    font-size: 3.5rem;
    line-height: 4.8rem;
    font-weight: bold;
    margin-bottom: 6.8rem;

    @include mobile {
      font-size: 2.5rem;
      line-height: 3.4rem;
      margin-bottom: 2.3rem;
    }
  }

  &__desc {
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: bold;
    margin-bottom: 1.1rem;

    @include mobile {
      margin-bottom: 1.5rem;
    }
  }

  &__btn {
    width: 24.9rem;
    height: 4.4rem;
    justify-content: space-between;
    padding: 0 1.8rem;

    @include mobile {
      width: 100%;
      max-width: 32rem;
    }

    .icon {
      font-size: 2.8rem;
    }

    @include hover {
      background: $white;
      color: $orange;
    }
  }
}
